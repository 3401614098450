import { useEffect, useState } from "react"
import { get } from "../../../helpers/storage_helper"
import {getSelectOptionsFromResult, select_custom_styles, setOptionsListFromAppApi, setOptionsToStateFromApi} from "../../../helpers/common_helper"
import {req} from "../../../helpers/common_helper"
import { getMultiSelectOptions } from "../../../actions/common_actions"
import { useDispatch, useSelector } from "react-redux"
import { setScreachedFields } from "../../../Redux/Slices/CommonSearchSlice"
import { getLoanSearchCriteria } from "../../../actions/common_actions"
import Select from "react-select";
import { Button, Form } from "react-bootstrap"
import DatePicker from 'react-datetime';


const CommonSearchCriteria = (props) => {

    const dispatch = useDispatch()
    const common_search_data = useSelector(state => state.CommonSearch)
    const [searchFields, setSearchfields] = useState([]);
    const [checkBoxFields, setCheckBoxFields] = useState([]);
    const language_json = get('language_json')
    const [allOptions, setAllOptions] = useState({})
    const [actorId, setActorID] = useState("")
    const [dinamicSearchFields, setDinamicSearchFields] = useState([])
    const [dinamicCheckBoxField, setDinamicCheckBoxField] = useState([])
    const [session1, setSession1] = useState('')
    const [session2, setSession2] = useState('')


    useEffect(()=>{
        handleApiCall()
        getAccPrvdrs();
        getRelMgrOptions();
        getSearchFields();
        setEmptyGlobalState()
    }, [])

    useEffect(() => {
        handleDisableSession();
    }, [common_search_data?.[props.id]]);

    const handleDisableSession = () => {
        let common_data = common_search_data?.[props.id] ?? {}
        let session_1 = false
        let session_2 = false
        Object.keys(common_data).map((key, i) => {
            if(key != 'req_parameter' && common_data[key].selected_data){
                session_1 = true
            }else if(key == 'req_parameter' && common_data[key] && common_data[key].selected_data){
                session_2 = true
            }
        })
        setSession2(session_1 ? 'disable_div' : '')
        setSession1(session_2 ? 'disable_div' : '')
    }

    const setEmptyGlobalState = () => {
        let key = props.id
        let value = {}
        dispatch(setScreachedFields({key, value}))
    }

    const getRelMgrOptions = async () => {
        let options = await setOptionsToStateFromApi("rel_mgr", {associated_with:'flow'},"multiselect_rm_name", "kyc")
        setAllOptions(prevOptions => ({...prevOptions,flow_rel_mgr: options}));
    }

    const getAccPrvdrs = async() => {
        let option = await setOptionsListFromAppApi('common', 'list', {status:'enabled'} , ['acc_prvdr_code', 'name'], "acc_prvdrs")
        setAllOptions(prevOptions => ({...prevOptions,acc_prvdr: option}));
    }


    const handleApiCall = async() => {
        var acc_prvdr_code = get('acc_prvdr_code');
        let screen = ""
        if(props.mode == 'loan'){
            screen = 'loan_search'
        }
        await getLoanSearchCriteria(req({screen, acc_prvdr_code, "status": "enabled"}))
                    .then((response) => {
                      if(!response){return };
                    var result = response.data;
                    let all_options = allOptions
                    for (var dd_name in result) {
                        var name_list = result[dd_name];
                        let options = []
                        let dd_name_list = ['loan_status', 'loan_appl_status', 'borrower_status', 'territory_list', 'profile_status', 'activity_status', 'risk_category', 'write_off_status_list', 'location', 'sub_lender_code'];
                        if(dd_name_list.includes(dd_name)){
                            const data_holders = ["data_code", "data_value"];
                            options = getSelectOptionsFromResult(name_list, dd_name, data_holders,false);
                        }else{	
                            options = getSelectOptionsFromResult(name_list, dd_name);
                        }
                        all_options[dd_name] = options

                    }
                    setAllOptions(prevOptions => ({...prevOptions, ...all_options}));

               });
    }

    const getSearchFields = () => {
        let fields = []
        let check_box_fields = []
        fields = [
            {id : "status", label : language_json.status, type : 'select', options_name : 'loan_status', screen:['loan']},
            {id : 'acc_prvdr_code', label : language_json.account_provider, type : 'select', options_name:"acc_prvdr", screen : ['all']},
            {id : 'flow_rel_mgr_id', label : language_json.flow_rel_mgr, type : 'multi_select', options_name : "flow_rel_mgr", screen : ['all']},
            {id : 'product_id', label : language_json.product_name, type : 'select', options_name : 'product_name_list', screen : ['loan', 'loan_appl']},
            {id : 'write_off_status', label : language_json.write_off_status, type : 'select', options_name : 'write_off_status_list', screen : ['loan']},
            {id : 'territory', label : language_json.territory, type : 'multi_select', options_name : "territory", screen : ['loan']},
            {id : 'district', label : language_json.district, type : 'multi_select', options_name : "district", screen : ['loan']},
            {id : 'sub_lender_code', label : language_json.lender, type : 'select', options_name : 'sub_lender_code', screen : ['loan']},
        ]

        check_box_fields = [
            {id : 'voided_fa', label : language_json.include_void_fa, type : 'checkbox', options_name : null, screen:['loan']},
            {id : 'exclude_wo_fa', label : language_json.exclude_writt_off_fa, type : 'checkbox', options_name : null, screen:['loan']},
        ]

        let fields_filter = fields.filter((val, i) => val.screen.includes(props.mode, val.screen) || val.screen.includes('all', val.screen))
        let checkbox_fields_filter = check_box_fields.filter((val, i) => val.screen.includes(props.mode, val.screen) || val.screen.includes('all', val.screen))
        setSearchfields(fields_filter)
        setCheckBoxFields(checkbox_fields_filter)
    }


    const hadleAllFilterData = (id, data, type, label) => {
        let selected_data = {}
        let key = props.id
        let value = {}
        if(type == 'select' && id == 'status'){
            value = handleStatusChange(data.target.value)
        }else{
            value = common_search_data?.[props.id] ?? {}
        }
        if(type == "select"){
            if(data.target.value){
                selected_data  = {value : data.target.value, label : data.target.selectedOptions[0].text}
            }
        }else if(type == 'multi_select'){
            selected_data = {value : data.length > 0 ? data : null, label : ""}
        }else if(type == 'checkbox'){
            let val = false
            let check_box_label = "false"
            if(data.target.checked){
                val = true
                check_box_label = "True"
            }
            selected_data = {value : val, label : check_box_label}
        }else if(type == 'date'){
            selected_data = {value : moment(data).format("YYYY-MM-DD"), label : moment(data).format("DD MMM YYYY")}
        }else if(type == 'text'){
            selected_data  = {value : data.target.value, label : data.target.value}
        }

        value = {...value, [id] : {}}
        if(selected_data && selected_data.value){
            value[id] = {selected_data, type, filter_name : label}
        }else{
            delete value[id]
        }
        

        dispatch(setScreachedFields({key, value}))
    
    }

    const handleStatusChange = (value) => {
        setDinamicCheckBoxField([])
        setDinamicSearchFields([])
        let  dinamic_search_fields = []
        let dinamic_check_box_fields = []
        const actorIgnoredKeys = ["_from", "_to", "_today", actorId, "include_setld_fa"];

        let common_data = common_search_data?.[props.id] ?? {}

        let modified_common_state = Object.keys(common_data).reduce((acc, key) => {
            if (!actorIgnoredKeys.some(ignoredKey => key.includes(ignoredKey))) {
                acc[key] = common_data[key];
            }
            return acc;
        }, {});

        let show_from_until = false 
        let show_by_today = false
        let name_of_id = null
        let date_label = null
        let actor_id = null
        let actor_select_option = null
        let extra_check_box_field = null
        if(props.mode == 'loan'){
            if(value == "pending_disbursal"){
                show_from_until = true
                show_by_today = true
                name_of_id = "loan_approved_date"
                date_label = "Approved"
                actor_id = "loan_approver_id"
                actor_select_option = "approver_list"
            }else if(value == 'ongoing' || value == "disbursed"){
                show_from_until = true
                name_of_id = "disbursal_date"
                date_label = language_json.disbursed
            }else if(value == "Overdue"){
                show_from_until = true
                name_of_id = "due_date"
                date_label = language_json.due
            }else if(value == "settled"){
                show_from_until = true
                show_by_today = true
                name_of_id = "paid_date"
                date_label = language_json.payment_captured
                actor_id = "paid_by"
                actor_select_option = "payer_list"
            }else if(value == 'due'){
                extra_check_box_field = {id : 'include_setld_fa', label : "Include Settled FA", type : 'checkbox', options_name : null, screen : ['loan']}
            }
        }

        if(show_from_until){
            dinamic_search_fields.push({id:name_of_id+"__from", label : date_label + " From", type : 'date', options_name : null, screen : [props.mode]})
            dinamic_search_fields.push({id:name_of_id+"__to", label : date_label + " Until", type : 'date', options_name : null, screen : [props.mode]})
        }

        if(show_by_today){
            dinamic_check_box_fields.push({id:name_of_id+"__today", label : date_label + " " + language_json.today, type : 'checkbox', options_name : null, screen : [props.mode]})
        }

        if(actor_id){
            setActorID(actor_id)
            dinamic_search_fields.push({id:actor_id, label : date_label + ' ' + language_json.by, type : 'select', options_name : actor_select_option, screen : [props.mode]})
        }

        if(extra_check_box_field){
            dinamic_check_box_fields.push(extra_check_box_field)
        }

        setDinamicSearchFields(dinamic_search_fields)
        setDinamicCheckBoxField(dinamic_check_box_fields)
        return modified_common_state
        
    }

    const resetAll = () => {
        setEmptyGlobalState()
        setDinamicCheckBoxField([])
        setDinamicSearchFields([])
    }

    const select_fields = [...searchFields, ...dinamicSearchFields]
    const check_box_fields = [...checkBoxFields, ...dinamicCheckBoxField]
    
    return(
        <div>
            <form method="get" action="/" className="form-horizontal" style={{backgroundColor:'#202940'}} autocomplete="off">
                <div className={`row no-margin ${session1}`}>
                    {[...select_fields, ...check_box_fields].map((val, i) => (
                        <div className="col-md-3 marT-5" key={i + '_' + val.id}>
                            {
                            (val.type == 'select' && val.id == 'status') ? 
                            <div className="my-2">
                                <div className="" style={{position:'relative'}}>
                                    <font className="text-danger" style={{position:'absolute',top:0,left:0}}>   *  </font>
                                    <select className="form-control" id={val.id} value = {common_search_data?.[props.id]?.[val.id]?.selected_data?.value ?? ''} onChange={(e) => hadleAllFilterData(val.id, e, val.type, val.label)} required>
                                        <option value="">{val.label}</option>
                                        {allOptions[val.options_name]}
                                    </select>
                                </div>
                            </div>
                            : 
                                
                            val.type == 'select' ?  
                            <div className="my-2">
                                <select className="form-control" id={val.id} value = {common_search_data?.[props.id]?.[val.id]?.selected_data?.value ?? ''} onChange={(e) => hadleAllFilterData(val.id, e, val.type, val.label)} required>
                                    <option value="">{val.label}</option>
                                    {allOptions[val.options_name]}
                                </select>
                            </div>
                            :

                            val.type == 'multi_select' ? 
                            <div className="my-2">
                                <Select
                                value = {common_search_data?.[props.id]?.[val.id]?.selected_data?.value ?? ''}
                                onChange = {(e) => hadleAllFilterData(val.id, e, val.type, val.label)}
                                options = {val.id == 'flow_rel_mgr_id' ? allOptions[val.options_name] : getMultiSelectOptions(val.options_name)}
                                placeholder={val.label}
                                styles={select_custom_styles()}
                                classNamePrefix="my-select" 
                                isMulti/> 
                            </div>
                            :

                            val.type == 'date' ? 
                            <Form.Group className={`no-margin searchInput_height`}>
                                <Form.Label className='no-margin pl-3'>{val.label}<span className='text-danger'>*</span></Form.Label>
                                <DatePicker closeOnSelect={true} dateFormat="DD MMM YYYY" timeFormat={false} required="required"
                                value={common_search_data?.[props.id]?.[val.id]?.selected_data ? new Date(common_search_data?.[props.id]?.[val.id]?.selected_data.value) : ""}
                                onChange = {(e) => hadleAllFilterData(val.id, e, val.type, val.label)}
                                />
                            </Form.Group>
                            :

                            val.type == 'checkbox' ? 
                            <div className="my-2">
                                <div className="ml-2 mt-4">
                                    <label className="checkbox-inline no-margin mt-2">
                                        <input id="include_setld_fa" type="checkbox" checked = {common_search_data?.[props.id]?.[val.id]?.selected_data?.value}  onChange={(e) => hadleAllFilterData(val.id, e, val.type, val.label)}/>
                                        <span className="ml-4">{val.label}</span>
                                    </label>
                                </div>
                            </div>
                            :

                            null
                            }
                        </div>
                    ))}
                   
                </div>
                {props.mode == 'loan' && 
                <div className={`row no-margin ${session2}`}>
                    <div className="col-12 text-center">
                        <h3 className="font-weight-bolder no-margin" style={{padding:5}}>OR</h3>
                    </div>
                    <div className="col-md-5 searchInput_height">
						<div className={`form-group`} >
								<Form.Label className='no-margin'>Any {language_json.cust_id} / {language_json.account_num} / {language_json.mobile_no} / {language_json.national_id} / {language_json.msisdn}</Form.Label>
								<Form.Control id="req_parameter" value={common_search_data?.[props.id]?.req_parameter?.selected_data?.value ?? ''} onChange={(e) => hadleAllFilterData('req_parameter', e, 'text', 'Text')}>
								</Form.Control>
						</div>
					</div>
                    <div className="col-md-3">
                        <div className="pt-3">
                            <Button id='view' onClick={props.onSearch} className={common_search_data?.[props.id]?.req_parameter?.selected_data?.value ?? 'disable_div'}>{language_json.view}</Button>
                        </div>
                    </div>
                </div>
                }
                <div className="row no-margin">
                    <div className="col-12 text-center" style={{paddingBottom:15,paddingTop:5}}>						
						<Button type="reset" variant="secondary" onClick={resetAll}>{language_json.reset}</Button>	&nbsp;
						<Button id="searchbtn" varient="info" disabled={props.disable_btn} className={`btn-primary`} onClick={props.onSearch}>{language_json.search}</Button> &nbsp;										
					</div>
                </div>
            </form>
        </div>
    )
}

export default CommonSearchCriteria;