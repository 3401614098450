import React from 'react';

import BorrowerSearchContainer from '../UI/borrower/container/borrower_search_container';
import IndividualBorrowerContainer from '../UI/borrower/container/individual_bwr_container';

import ViewIndividualBorrowerContainer from '../UI/borrower/container/view_individual_bwr_container';


import FlowRelationshipManagerContainer from '../UI/relationship_manager/container/flow_relationship_manager_container';
import AccprvdrRelationshipManagerContainer from '../UI/relationship_manager/container/accprvdr_relationship_manager_container';
import ListAccprvdrRelationshipManagerContainer from '../UI/relationship_manager/container/list_accprvdr_relationship_manager_container';
import listFlowRelationshipManagerContainer from '../UI/relationship_manager/container/list_flow_relationship_manager_container';

import ProductSelectionContainer from '../UI/loan_appl/container/product_selection_container';
import LoanApplSearchContainer from '../UI/loan_appl/container/loan_appl_search_container';
import ViewLoanApplContainer from '../UI/loan_appl/container/view_loan_appl_container';
import LoanSearchContainer from '../UI/loan/container/loan_search_container';
import ViewLoanContainer from '../UI/loan/container/view_loan_container';

import UploadCustomerTransactionContainer from '../UI/borrower/container/upload_customer_transaction_container';
import CreateUserContainer from "../UI/user/container/CreateUserContainer";
import ListUserContainer from "../UI/user/container/ListUserContainer";
import RMReassignment from '../UI/relationship_manager/container/RMReassignment';
import RMRoutesContainer from '../UI/relationship_manager/container/RMRoutes';
import SalesContainer from '../UI/loan/container/SalesContainer';
import ListInvestors from '../UI/user/container/ListInvestors';

const user_routes = [

    {path:"/borrower/indiv/edit/:cust_id",element:<IndividualBorrowerContainer mode='view' title="View/Edit Customer"/> },
    {path:"/borrower/indiv/view/:cust_id",element:<ViewIndividualBorrowerContainer mode='view' title="View Customer"  /> },
    {path:"/borrower/search",element:<BorrowerSearchContainer /> },
    {path:"/upload_cust_txns",element:<UploadCustomerTransactionContainer mode="create" /> },


    {path:"/rm_live_locations",element:<RMRoutesContainer type = "live_locations"   key={"live_locations"}/> },
    {path:"/rm_live_routes",element:<RMRoutesContainer type = "live_routes" key={"live_routes"}  /> },
    {path:"/reassign",element:<RMReassignment /> },
    {path:"/relationship_manager/flowrlspmanager/create",element:<FlowRelationshipManagerContainer mode='create' title="Create FLOW Relationship Manager" /> },
    {path:"/relationship_manager/flowrlspmanager/view/:relationshipManager_id",element:<FlowRelationshipManagerContainer mode='view' title="View/Edit Relationship Manager"  /> },
    {path:"/relationship_manager/flowrlspmanager/list",element:<listFlowRelationshipManagerContainer /> },


    {path:"/relationship_manager/dprlspmanager/create",element:<AccprvdrRelationshipManagerContainer mode='create' title="Create Account Provider Relationship Manager" /> },
    {path:"/relationship_manager/dprlspmanager/view/:relationshipManager_id",element:<AccprvdrRelationshipManagerContainer mode='view' title="View/Edit Relationship Manager" /> },
    {path:"/relationship_manager/dprlspmanager/list",element:<ListAccprvdrRelationshipManagerContainer mode='list' /> },
  


    {path:"/user/list",element:<ListUserContainer mode='list' /> },
    {path:"/user/create",element:<CreateUserContainer id='person' mode='create' title="Create User" /> },
    {path:"/user/view/:person_id",element:<CreateUserContainer id='person' mode='view' title="Edit User"  /> },
    {path:"/investor/create",element:<CreateUserContainer id='investor' mode='create' title='Create Investor' invstr_key ='investor'   /> },
    {path:"/investor/view/:person_id",element:<CreateUserContainer id='investor' mode='view' title='Edit Investor' invstr_key ='investor' /> },
    {path:"/investor/list",element:<ListInvestors id='investor' mode='list' title='Investors List' invstr_key =''   /> },

    {path:"/fa_appl/search",element:<LoanApplSearchContainer /> },
    {path:"/fa_appl/view/:loan_appl_doc_id",element:<ViewLoanApplContainer mode="view" /> },
    {path:"/fa_appl",element:<ProductSelectionContainer mode="search" key = {'search'}/> },
    {path:"/fa_appl_for/:cust_id",element:<ProductSelectionContainer mode="result"  key={'result'}/> },

    {path:"/fa/view/:loan_doc_id",element:<ViewLoanContainer  /> },
    {path:"/fa/search",element:<LoanSearchContainer  /> },
    {path:"/sales/:cust_id",element:<SalesContainer  /> }

]


export default user_routes;