import React, { useRef } from 'react';
import { Avatar, Badge, Box, IconButton, Typography, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import CustomButton from '../../MUI/Components/CustomButton';
import { useNavigate } from 'react-router-dom';
import { get } from '../../helpers/storage_helper';
import { dd_value, update, user_profile_pic } from '../../actions/common_actions';
import { useState } from 'react';
import { capitalize_first_letter, capitalize_first_letter_string, encodeImageFileAsURL, req } from '../../helpers/common_helper';
import { useDispatch, useSelector } from 'react-redux';
import { changeHeaderSlice } from '../../Redux/Slices/HeaderSlice'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { useEffect } from 'react';

export default function UploadProfilePic({header_profile_pic}) {
    const {profile_path,user_email} = useSelector(state => state.HeaderSlice)

    const theme = useTheme()
    const fileInputRef = useRef(null);
    const dispatch = useDispatch()
    const [file_details,setfile_details] = useState({
        base64_format: profile_path
    })

   

    const navigate = useNavigate()
    const handleFileChange = (event) => {
        let file_info;
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64_format = reader.result;
            file_info = {
              ...file_details,
              file_data : base64_format,
              file_type: file['type'],
              file_name: file['name'],
              file_data_type: "data-url",
              type: 'update',
              file_of :"photo_pps"
            };
            
            setfile_details(file_info);
            dispatch(changeHeaderSlice({ profile_path: base64_format, showavatar: false }));
           
            header_profile_pic(file_info)
          };
          
          reader.readAsDataURL(file); 
        }
      };



    const handleEditClick = () => {
        fileInputRef.current.click();
    };

    const log_out = {
        backgroundColor: theme.palette.custom_colors.secondary_500,
        color: theme.palette.custom_colors.secondary_a_50,
        borderRadius: '7px',
        fontSize: "1rem", fontWeight: '600',
        textTransform: 'none'
      }

    return (
        <Box  >
           <Box sx={{position:"absolute",right:"20px",top:"15px" }} onClick={()=>{ dispatch(changeHeaderSlice({showavatar:false}))}}>
                <HighlightOffOutlinedIcon className='pointer' sx={{ fontSize: 23 ,color:theme.palette.custom_colors.secondary_a_500}} />
           </Box>
            <Box className="d-flex justify-content-center mt-2">
                <Badge
                    overlap="circular"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={
                        <IconButton
                            onClick={handleEditClick}
                            sx={{
                                backgroundColor: '#006DFF',
                                width: 20,
                                height: 20,
                                color: '#fff',
                                padding: '5px',
                            }}
                        >
                            <EditIcon sx={{ fontSize: 13 }} />
                        </IconButton>
                    }
                >
                    <Avatar
                        alt={get('user_name')}
                        src={`${file_details ? file_details.base64_format:''}`}
                        sx={{
                            width: 70,
                            height: 70,
                        }}
                    />
                </Badge>

                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept="image/png,image/jpeg"
                />

            </Box>
            <Typography variant="m_inter_medium_regular_white" className="text-center mt-3" component={'p'} >
                { capitalize_first_letter_string(((get('user_name')).toLowerCase())) }
            </Typography>

            <Typography variant="s_inter_medium_gray" className="text-center mt-1" component={'p'} >
                {user_email}
            </Typography>

            <Typography variant="s_inter_medium_white" className="text-center mt-2" component={'p'} >
                {dd_value(get('role_codes'),"role_codes")}
            </Typography>
            <Box className="mb-3 mt-4">
                <CustomButton text={"Log Out"} style={log_out} onClick={()=>{navigate('/logout')}} variant={"medium"} startIcon={<LogoutIcon />} />
            </Box>

        </Box>

    );
}
;