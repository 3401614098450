import React from 'react';
import { createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { deepOrange, red } from '@mui/material/colors';
import { typography } from './Variants/typography';
import { dialog } from './Variants/dialog';
import { button_variants } from './Variants/button_variants';
import  custom_colors  from './colors';
import { input_field_variants } from './Variants/input_field_variants';
import { accordion_variants } from './Variants/accordion_variants';


// const datas = useSelector(state => state.themeColors)
const createCustomTheme = (mode) => {
   const theme = createTheme({
        palette: {
                mode, 
                primary: {
                    main: custom_colors[mode].primary_500,
                    contrastText : custom_colors[mode].secondary_a_50,  
                },
                secondary:{
                    main: custom_colors[mode].secondary_500,
                },
                background: {
                    default:custom_colors[mode].primary_900,
                },
                custom_colors:{
                    ...custom_colors[mode]
                },


        },
        typography: {

            fontFamily: '"Poppins", Arial, sans-serif',
            fontSize:'16px'
        },
    })
    theme.components = {
            MuiTypography: {
                variants:[...typography(theme)],
            },
            MuiDialog:{
                variants:[
                    ...dialog,
                ]
            },
            MuiButton:{
               variants : [
                    ...button_variants
                ]
            },
            MuiTextField:{
                styleOverrides:{
                    root:({theme})=>({
                        "& .MuiInputBase-input" :{
                            width:'-webkit-fill-available',
                        },
                        "& .md-form-group" :{
                            width:'-webkit-fill-available'
                        },
                        ...input_field_variants(theme)
                        
                    })
                 }
                    
            },
            MuiAccordion:{
                styleOverrides:{
                    root:({theme})=>({
                        ...accordion_variants(theme)
                    })
                }
            },
            MuiPaginationItem:{
                styleOverrides:{
                    root:({theme})=>({
                        "&.MuiPaginationItem-root":{
                            color:theme.palette.primary.contrastText
                         },
                         "&.Mui-selected":{
                            backgroundColor:theme.palette.primary.main
                         }
                    })
                       

                    
                }
            }
       
        }
   return theme

}

export default createCustomTheme;

