import React from 'react';
import FlowContainer from '../../common/container/core/FlowContainer';
import { viewRMTarget,updateRMTarget,checkTargets } from '../../../actions/relationship_manager_actions';
import Assign from '../component/Assign';
import '../../../styles/flow.css';
import { Card, Button, InputGroup, ThemeProvider } from 'react-bootstrap';
import { format } from 'date-fns';
import {Form} from 'react-bootstrap';
import CommonReactTable from '../../../helpers/commonReact_table';
import { suppressDeprecationWarnings } from 'moment';
import { check_priv } from '../../../helpers/storage_helper';
import { BasicReactTable } from '../../../helpers/react_table';


class Assign_container extends FlowContainer {


    state = {
        columns: [],
        lists: [],
        copy: [],
        show_target_text: false,
        add_targets : false,
        new_target :false,
        view :null,
        assigned: {},
        edited_target:{},
        editable : false ,   
        save :false,
        edit:false,
        next_targets:{}    

    }

    componentWillMount() {
        this.handleCurDate();
        this.handleButtonName();
        
    }

    handleComponentChange = (event, comp_id) => {
        const id = event.target.id;
        const value = event.target.value;
        this.setState({ [id]: value });
    }


    handleCurDate = () => {
        const date = new Date()
        const cur_month = format(date, 'yyyy-MM-01')
        const month = new Date(cur_month);
        const month_name = date.toLocaleString('en-US', {month: 'long'})
        this.setState({past_target: false, show_target_text: false, add_targets: false,cur_month,view_month_name : month_name+" Month Targets" })
        this.drawTable(cur_month)
    }

    showAddTarget = (event) => {
        const dt = new Date()
        const date = new Date(dt.getFullYear(), dt.getMonth(), 1)
        const cur_month = format(date, 'yyyy-MM-01')
        const next_month = new Date(date.setMonth(date.getMonth()+1))
        const next_month_name = next_month.toLocaleString('en-US', {month: 'long'})
        this.setState({view_month_name : next_month_name+" Month Targets", show_target_text: true, add_targets:true, past_target : false, new_target : false,cur_month})
        this.drawTable(cur_month);
    }

    showPastTarget = (event) => {
        this.setState({ past_target: true, show_target_text: false, new_target : false, add_targets: false})
        // this.drawTable(this.state.cur_month)  
    }

    handleSearch = (event) => {
        const cur_date =  new Date()
        const next_month = cur_date.setMonth(cur_date.getMonth()+1)
        const date = new Date(this.state.past_month);
        if(date <= next_month){
            const month_name = date.toLocaleString('en-US', {month: 'long'})
            this.setState({view_month_name : month_name+" Month Targets" })
            this.drawTable(this.state.past_month)  
        }else{
            this.drawTable(this.state.past_month)  
        }
        
    }

    addTarget = (event,row) => {
        console.log(row)
        const rel_mgr_id = row.rm_id
        const target = Number(event.target.value)
        const assigned = this.state.assigned;
        assigned[rel_mgr_id] = target;
        this.setState({assigned})
    }

    handleSubmit = (event) => {
       const dt = new Date(Date.now());
       const date = new Date(dt.getFullYear(), dt.getMonth(), 1)
       const cur_month = format(date, 'yyyy-MM-01')
       const next_month = date.setMonth(date.getMonth()+1)
       const month = format(date, 'yyyy-MM-01')
       this.setState({new_target : true, edit:true})
       updateRMTarget(this.req({data : {target :this.state.assigned, target_month : month, month : cur_month}}))
       this.handleView()
    }

    handleView = () =>{
        this.setState({new_target : true, show_target_text:false, editable:false, save:false})
        this.handleCurDate()
    }

    handleButtonName(){
        const dt = new Date(Date.now());
        const date = new Date(dt.getFullYear(), dt.getMonth(), 1)
        const month_name = date.toLocaleString('en-US', {month: 'long'})
        const next_month = new Date(date.setMonth(date.getMonth()+1))
        const next_month_name = next_month.toLocaleString('en-US', {month: 'long'})
        this.setState({cur_month_name:month_name+' Targets',next_month_name : 'Add '+next_month_name+' Targets'})  
    }

    getPastTarget(){
        this.state.row_datas && this.state.row_datas.forEach((data) => {
            const rm_id = data.rm_id
            const assigned = this.state.assigned
            assigned[rm_id] = 0
           this.setState({assigned})
            
        });
    }

    getNextTarget(){
        this.state.row_datas && this.state.row_datas.forEach((data) => {
           
            const rm_id = data.rm_id
            const next_targets = this.state.next_targets

            if(data.next_targets !== null){
                next_targets[rm_id] = Number(data.next_targets)
                this.setState({next_targets})
            }
            else{
                this.setState({next_targets : {}})
            }
            
            
        });
    }

    Editable(event,row){

        const rel_mgr_id = row.original.rm_id
        const target = Number(event.target.value)
        const edited_target = this.state.edited_target;
        edited_target[rel_mgr_id] = target;
        this.setState({edited_target})
    }

    handleEditValues = () =>{
       
        this.setState({editable : true, save : true, edit:false})  
             
    }

    cellEditable(row){

       
        if(this.state.editable == true){
            return <td><input type={'text'} style={{width:"100px"}} defaultValue={this.state.edited_target ? this.state.edited_target[row.rm_id] :'' } onChange={(e) => this.Editable(e,row)}></input></td>
        }else{
            
            return row.next_targets
        }
    }

    updateTarget(row){
        
            // return <td><input type={'text'} style={{width:"100px"}} defaultValue={row.value} onChange={(e) => this.addTarget(e,row)}></input></td>
            if(Object.keys(this.state.next_targets).length == 0){
                return <form> <td><input type='text' style={{width:"100px"}} autoComplete = "off" defaultValue={this.state.assigned ? this.state.assigned[row.rm_id] :''} id={row.id}  onChange={(e) => this.addTarget(e,row)}></input></td></form>
            }else{
               
                return row.next_targets
            }
        }

    handleSave = (event) => {

        const dt = new Date(Date.now());
        const date = new Date(dt.getFullYear(), dt.getMonth(), 1)
        const cur_month = format(date, 'yyyy-MM-01')
        const next_month = date.setMonth(date.getMonth()+1)
        const month = format(date, 'yyyy-MM-01')
        this.setState({new_target : true,show_target_text:false})
        updateRMTarget(this.req({data : {target :this.state.edited_target, target_month : month, month : cur_month}}))
        this.handleView()
     }


    drawTable(req_month) {

        viewRMTarget(this.req({ month:req_month, view:'next_month' }))
        
            .then((response) => {
                
                if (!response) { return };
                if (response.status === "success") {
                    this.setState(state => state.columns = this.columndatas(response.data.headers))
                    if (response.data.records_arr) {
                        this.setState({  row_datas: response.data.records_arr,copy: response.data.records_arr })
                        this.getPastTarget()
                        this.getNextTarget()
                    }
                }
            })
    }

    columndatas(data) {
        
        return [
            {
                
                Header: <p style={{fontSize:"13px"}}>{data.rm_name}</p>,
                id: "rm_name",
                accessor: row => row.rm_name,
                minWidth:100,
                maxWidth:375  
            },
            {
                Header: <p style={{fontSize:"13px"}}>{data.month}</p>,
                id: "targetted",
                accessor: row => row.targetted,
                minWidth:50,
                maxWidth:200
            },
            {
                Header: <p  style={{fontSize:"13px"}}>{data.month_accquired}</p>,
                id: "month_accquired",
                accessor: row => row.month_accquired,
                minWidth:50,
                maxWidth:200
            },
            {
                Header: <p  style={{fontSize:"13px"}}>{data.accquired}</p>,
                id: "accquired",
                accessor: row => row.accquired,
                minWidth:50,
                maxWidth:200,
                sortMethod: (a, b) => parseInt(a)-parseInt(b)
            },
            // {
            //     Header: <p  style={{fontSize:"13px"}}>{data.add_targets}</p>,
            //     accessor: "target_entries",
            //     Cell : row=>this.updateTarget(row),
            //     show: this.state.show_target_text,
            //     minWidth:50,
            //     maxWidth:200
            // },
            // {
            //     Header: <p  style={{fontSize:"13px"}}>{data.add_targets}</p>,
            //     id: "next_targets",
            //     accessor: row => row.next_targets,
            //     show : this.state.new_target,
            //     Cell : row=>this.cellEditable(row),
            //     minWidth:50,
            //     maxWidth:200
                
            // }
            {
                Header: <p  style={{fontSize:"13px"}}>{data.add_targets}</p>,
                id:"update_next_targets",
                accessor: "update_next_targets",
                Cell : cell=>this.updateTarget(cell.row.original),
                show: this.state.show_target_text ? true: false,
                minWidth:50,
                maxWidth:200
            },
            {
                Header: <p  style={{fontSize:"13px"}}>{data.add_targets}</p>,
                id: "next_targets",
                accessor: row => row.next_targets,
                show : this.state.new_target ? true :false ,
                Cell : ({row})=>this.cellEditable(row.original),
                minWidth:50,
                maxWidth:200
                
            }
           
        ]
    };




    render() {
        
       
        return (
            <div>
                <div className='mb-3 mt-2'>
                    <h5 style={{ color: "white" }}>{this.props.title}</h5>
                </div>

                <div className="row mb-5" >
                    <ul class="d-flex" >
                        
                        <li className=" pr-1">
                            <Button onClick={this.showPastTarget}>View Targets</Button>
                        </li>
                        <li className=" pr-1">
                            <Button onClick={this.handleCurDate}>{this.state.cur_month_name}</Button>
                        </li>
                        {check_priv("rm_target","edit") && 
                        <li className=" pr-1">
                            <Button disabled = {this.state.past_target}onClick={this.showAddTarget}>{this.state.next_month_name}</Button>
                        </li>
                        }
                        
                    </ul>
                </div>
                {this.state.past_target &&
                    <div className='d-flex mb-3'>

                        <Assign id="past_month" data={this.state.past_month} cur_date={this.state.cur_month} onComponentChange={this.handleComponentChange} />

                        <div className='col-mt-2  mt-4'>
                            <Button onClick={this.handleSearch} >Show</Button>
                        </div>

                    </div>
                }
                
                <div className='text-center'>
                    <h5 style={{ color: "white" }}>{this.state.view_month_name}</h5>
                </div>
                
                
                <div className="card-body m-auto" style={{width: '850px'}}>
                    {/* <CommonReactTable showPag={true} defaultPageSize={50} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} resizable={false} defaultSorted={[{ id: "month_accquired", asc: true }]} searchData={[{view_month_name:this.state.view_month_name,title:'RM Targets'}]} /> */}

                    <BasicReactTable pagination={true} default_page_size={50} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} searchData={[{view_month_name:this.state.view_month_name,title:'RM Targets'}]} csv_file={false}   defaultSorted={[{ id: "month_accquired", asc: true }]}/>
                </div>

                {this.state.add_targets && Object.keys(this.state.next_targets).length == 0 &&
                    <div className='col-md-12 text-center mt-5'>
                    <Button onClick={this.handleSubmit}>Submit</Button>
                    </div>
                }
                {this.state.edit && 
                     <div className='text-center mt-5 '>
                     <Button onClick={this.handleEditValues}>Edit</Button>
                    </div>
                }
                {this.state.save &&
                <div className='text-center mt-5'>         
                    <Button className='ml-5' onClick={this.handleSave}>Save</Button>
                </div>
                }

                <div className='mt-5 mb-5'>
                    <p style={{color : "white"}} >
                        NOTE ** Please ensure that you have Assigned Targets for Relationship Managers Before Start of the Next Month
                    </p>
                </div>
   
            </div>

        )
    }



}
export default Assign_container;