import { Navigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import Toggle from '../../common/component/Toggle';
import '../../../styles/flow.css';
import {
    getReport, getReportDate,
} from '../../../actions/report_actions';
import ReportChart from '../component/ReportChart';
import InputSelectionHeader from "../component/InputSelectionHeader";
import {Config, check_priv, get} from "../../../helpers/storage_helper";
import moment from "moment";
import MultiSelecter from '../../../helpers/multiSelecter';
import { MdPerson } from "react-icons/md";
import { RiLineChartLine } from "react-icons/ri";
import { AiFillPieChart } from "react-icons/ai";
import Select from "react-select";
import { getChartsRegion, getLoanSearchCriteria } from '../../../actions/common_actions';
import { inline } from 'react-tooltip';
import FreshChatChart from './fresh_chat_chart';
import ChartSelectionHeader from '../component/ChartSelectionHeader';
import ChartFilter from "../component/ChartFilter";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import { data } from 'jquery';
import isEqual from 'lodash/isEqual'; 

const  pardays = [
    { value: "5", label: "5" },
    { value: "10", label: "10" },
    { value: "15", label: "15" },
    { value: "30", label: "30" },
    { value: "60", label: "60" },
    { value: "90", label: "90" },
    { value: "120", label: "120" },
    { value: "270", label: "270" }
  ];
  
  const default_pardays = [
    { value: "5", label: "5" },
    { value: "10", label: "10" },
    { value: "15", label: "15" },
    { value: "30", label: "30" }
  ]

  const ageGroup = [
    { value: "youth", label: "18 to 35yrs" },
    { value: "elder", label: "Above 35yrs" },
  ];
export class ReportChartLive extends FlowComponent{
    state = {
        country_code : "*",
        country : "global",
        activeCurrency : "UGXS",
        activeCurrencyVal : "1",
        viewBy : false,
        toggle : true,
        dataLabel : true,
        startDate : moment().subtract('6', 'months').format("Y-MM"),
        endDate : moment().format("Y-MM"),
        dateWiseStartDate:moment().subtract(1, 'months').format("Y-MM-DD"),
        dateWiseEndDate : moment().format("Y-MM-DD"),
        normalendDate:moment().format("Y-MM"),
        normalstartDate : moment().subtract('6', 'months').format("Y-MM"),
        ProjectendDate:moment().endOf('year').format("Y-MM"), 
        chart_weekly:true,
        reportDate : null,
        dataProjections:false,
        cummulative:null,
        selectedMonth:6,
        projectionMonthDiff:null,
        isactive:false,
        dateWiseFilter:false, 
        target: false,
        show_target: false, 
        pardayWise: true,
        loader: false,
        no_data: false,
        chart_view: true,
        pardays :[],
        acc_prvdr_codes : [],     
        report_type_is_weekly:false   ,
        cohort_amount_type:false,
        cohortReportAPIcount:0  ,
        cohortData:[],
        cohortDataDiv:false,
        cohort_data_in_perc:true,
        chart_data:[],
        roundOff:true,  
        req_perc_data:true,                         
        selectedTab:'get_monthly_cust_report',
        selectedChart:'Monthly Customer Registration',
        lender : null,
        filter_type:null,
        filter_provider:null,
        chart_options:false,
        chart_header:false,
        options:true,
        data:false,
        target_data:[],
        chart_type:"",
        xaxistype:"",
        x_axis_format:"",
        colors:[],
        tickPlacement:false,
        backup_data:false,
        curr:"",
        show_filter:true,
        tab_loder_div:false,
        mixed_chart:false,
        role:false,
        filter_provider:null,current_prvdr_tab:null

    }
    reportChartData() {

        const { selectedTab, filter_type, filter_provider,viewBy, dateWiseFilter, show_target } = this.state;
        let activeCurrency = this.state.activeCurrency
        if(this.state.country_code === '*'){
            activeCurrency = 'USD'
        }
        if (selectedTab === 'get_monthly_cust_report'){
            if(this.state.monthlyCustReportofCountry && this.state.monthlyCustReportofCountry.length > 0) {
                let data;
                
                if (filter_type === null && filter_provider === null) {
                    data = viewBy === true ? this.state.monthlyCustReport : check_priv("charts", "target_projection") && !dateWiseFilter && show_target ? this.state.monthlyCustReportofCountry : [this.state.monthlyCustReportofCountry[0]];
                } else {
                    data = viewBy === true ? this.state.monthlyCustReport : this.state.monthlyCustReportofCountry;
                }
                
                if (filter_type !== null) {
                    data = data.filter(item => item.name !== 'Target' && item.name !== '');
                }
                this.setState({
                    data: data,
                    colors:["#00e396", "#ff3154",'#8ee3cf', "#ff9f9e", "#fadd17",'#d989b5', '#ff3154', '#ffe15d', '#8a5fc2', '#ffa07a', '#87cefa', '#90ee90', '#b0c4de', '#ff6347', '#c71585', '#20b2aa'],
                    chart_type: 'area',
                    xaxistype: 'category',
                    x_axis_format: dateWiseFilter ? true : undefined,curr :undefined,mixed_chart:false
                    
                },()=>this.setState({backup_data:this.state.data}));
            }
            
        }
        else if(selectedTab === 'get_reg_and_active_customer'){
            if(this.state.regAndActiveCust && this.state.regAndActiveCust.length > 0){
    
                this.setState({
                    data: this.state.regAndActiveCust,
                    colors:["#2980b9", "#2ecc71", "#f1c40f", "#3498db", "#27ae60", "#f39c12", "#1abc9c", "#e67e22", "#9b59b6", "#ff6b6b", "#48c9b0", "#f39c12"]                    ,
                    chart_type: 'bar',
                    xaxistype: 'category',
                    x_axis_format: this.state.dateWiseFilter ? true : undefined,
                    target_data: check_priv("charts", "target_projection") && this.state.show_target && !this.state.dateWiseFilter
                        ? [{
                            title: this.state.targetRaw?.Activecustomer?.title,
                            value: this.state.targetRaw?.Activecustomer?.value
                        }]
                        : undefined,
                    tickPlacement: true,curr :undefined,mixed_chart:false
                },()=>this.setState({backup_data:this.state.data}));
                
            }
            
        }
        else if(selectedTab === 'get_revenue_per_cust' ){
            if((this.state.revenuePerCustomer && this.state.revenuePerCustomer.length > 0) || (this.state.revenuePerCustomerForCountry && this.state.revenuePerCustomerForCountry.length > 0 )){
    
                this.setState({
                    data: this.state.viewBy === true ? this.state.revenuePerCustomer : this.state.revenuePerCustomerForCountry,
                    chart_type: 'area',
                    colors:["#00e396", "#ff3154", "#fadd17",'#d989b5', '#8ee3cf', '#ff9f9e', '#ffe15d', '#8a5fc2', '#ffa07a', '#87cefa', '#90ee90', '#b0c4de', '#ff6347', '#c71585', '#20b2aa'],
                    xaxistype: 'category',
                    x_axis_format: this.state.dateWiseFilter ? true : undefined,
                    show_target:false,curr :activeCurrency,mixed_chart:false
                },()=>this.setState({backup_data:this.state.data}));
                
            }
            
        }
        else if(selectedTab === 'get_revenue_per_fa' ){
            if((this.state.revPerFa && this.state.revPerFa.length > 0) || (this.state.revenuePerFaForCountry && this.state.revenuePerFaForCountry.length > 0)){
    
                this.setState({
                    data: this.state.viewBy === true ? this.state.revenuePerFa : this.state.revenuePerFaForCountry,
                    chart_type: 'area',
                    colors:["#00e396", "#ff3154", "#fadd17",'#d989b5', '#8ee3cf', '#ff9f9e', '#ffe15d', '#8a5fc2', '#ffa07a', '#87cefa', '#90ee90', '#b0c4de', '#ff6347', '#c71585', '#20b2aa'],
                    xaxistype: 'category',
                    x_axis_format: this.state.dateWiseFilter ? true : undefined,
                    show_target:false,curr :activeCurrency,mixed_chart:false
                },()=>this.setState({backup_data:this.state.data}));
                
            }
            
        }
        else if(selectedTab === 'get_tot_disb_report' ){
            if((this.state.totDisbursal && this.state.totDisbursal.length > 0) || (this.state.totDisbursalForCountry && this.state.totDisbursalForCountry.length > 0)){
    
                this.setState({
                    data: this.state.viewBy === true ? this.state.totDisbursal : this.state.totDisbursalForCountry,
                    chart_type: 'area',
                    colors:["#00e396", "#ff3154", "#fadd17",'#d989b5', '#8ee3cf', '#ff9f9e', '#ffe15d', '#8a5fc2', '#ffa07a', '#87cefa', '#90ee90', '#b0c4de', '#ff6347', '#c71585', '#20b2aa'],
                    xaxistype: 'category',
                    x_axis_format: this.state.dateWiseFilter ? true : undefined,
                    show_target:false,curr :activeCurrency,mixed_chart:false
                },()=>this.setState({backup_data:this.state.data}));
                
            }
            
        }
        else if(selectedTab === 'get_advances_disbursed_and_completed' ){
            if(this.state.AmountDisbursedAndCompleted && this.state.AmountDisbursedAndCompleted.length > 0){
                this.setState({
                    data: this.state.AmountDisbursedAndCompleted,
                    chart_type: 'area',
                    colors:["#00e396", "#ff3154", "#fadd17",'#d989b5', '#8ee3cf', '#ff9f9e', '#ffe15d', '#8a5fc2', '#ffa07a', '#87cefa', '#90ee90', '#b0c4de', '#ff6347', '#c71585', '#20b2aa'],
                    xaxistype: 'category',
                    x_axis_format: this.state.dateWiseFilter ? true : undefined,
                    show_target:false,curr :undefined,mixed_chart:false
                },()=>this.setState({backup_data:this.state.data}));
                
            }
            
        }
        else if(selectedTab === 'get_tot_settled_disb_count' ){
            if((this.state.viewBy === true ? this.state.totalSettledDisbCount : this.state.totalSettledDisbCountForCountry)){
    
                this.setState({
                    data: this.state.viewBy === true ? this.state.totDisbursal : this.state.totDisbursalForCountry,
                    chart_type: 'area',
                    colors:["#00e396", "#ff3154", "#fadd17",'#d989b5', '#8ee3cf', '#ff9f9e', '#ffe15d', '#8a5fc2', '#ffa07a', '#87cefa', '#90ee90', '#b0c4de', '#ff6347', '#c71585', '#20b2aa'],
                    xaxistype: 'category',
                    x_axis_format: this.state.dateWiseFilter ? true : undefined,
                    show_target:false,curr :undefined,mixed_chart:false
                },()=>this.setState({backup_data:this.state.data}));
                
            }
            
        }
        else if(selectedTab === 'get_ontime_payments' ){
            if((this.state.ontimePayment && this.state.ontimePayment.length > 0) || (this.state.ontimePaymentForCountry && this.state.ontimePaymentForCountry.length > 0)){
    
                this.setState({
                    data: this.state.viewBy ? this.state.ontimePayment : this.state.ontimePaymentForCountry,
                    chart_type: 'area',
                    colors:["#00e396", "#ff3154", "#fadd17",'#d989b5', '#8ee3cf', '#ff9f9e', '#ffe15d', '#8a5fc2', '#ffa07a', '#87cefa', '#90ee90', '#b0c4de', '#ff6347', '#c71585', '#20b2aa'],
                    xaxistype: 'category',
                    x_axis_format: this.state.dateWiseFilter ? true : undefined,
                    show_target:false,curr:"%",mixed_chart:false
                },()=>this.setState({backup_data:this.state.data}));
                
            }
            
        }
        else if(selectedTab === 'get_outstanding_fa' ){
            if((this.state.outstandingFA && this.state.outstandingFA.length > 0) || (this.state.outstandingFAForCountry && this.state.outstandingFAForCountry.length > 0)){
    
                this.setState({
                    data: this.state.viewBy === true ? this.state.outstandingFA  : this.state.outstandingFAForCountry,
                    chart_type: 'area',
                    colors:["#00e396", "#ff3154", "#fadd17",'#d989b5', '#8ee3cf', '#ff9f9e', '#ffe15d', '#8a5fc2', '#ffa07a', '#87cefa', '#90ee90', '#b0c4de', '#ff6347', '#c71585', '#20b2aa'],
                    xaxistype: 'category',
                    x_axis_format: this.state.dateWiseFilter ? true : undefined,
                    show_target:false,curr :activeCurrency,mixed_chart:false
                },()=>this.setState({backup_data:this.state.data}));
                
            }
            
        }
        else if(selectedTab === 'get_revenue' ){
            if((this.state.revenue && this.state.revenue.length > 0) || (this.state.revenueForCountry && this.state.revenueForCountry.length > 0)){
    
                this.setState({
                    data: this.state.viewBy === true ? this.state.revenue : this.state.revenueForCountry,
                    chart_type: 'line',
                    colors:["#00e396", "#ff3154", "#fadd17",'#d989b5', '#8ee3cf', '#ff9f9e', '#ffe15d', '#8a5fc2', '#ffa07a', '#87cefa', '#90ee90', '#b0c4de', '#ff6347', '#c71585', '#20b2aa'],
                    xaxistype: 'category',
                    x_axis_format: this.state.dateWiseFilter ? true : undefined,
                    show_target:false,curr :activeCurrency,mixed_chart:false
                },()=>this.setState({backup_data:this.state.data}));
                
            }
            
        }
        else if(selectedTab === 'get_total_and_overdue_fa' ){
            if((this.state.TotalAndOverdueFA)){
                let data = this.state.TotalAndOverdueFA
                if (this.state.role) {
                    data = data.filter(item => item.name.indexOf("Current Overdues") === -1);
                }
                this.setState({
                    data: data,
                    chart_type: this.state.role ? "bar" : false,
                    xaxistype: 'category',
                    colors:["#00e396", "#ff3154", "#fadd17",'#d989b5', '#8ee3cf', '#ff9f9e', '#ffe15d', '#8a5fc2', '#ffa07a', '#87cefa', '#90ee90', '#b0c4de', '#ff6347', '#c71585', '#20b2aa'],
                    x_axis_format: this.state.dateWiseFilter ? true : undefined,
                    tickPlacement: true,show_target:false,curr :undefined,
                    mixed_chart:get('role_codes') === "investor" ? false : true
                },()=>this.setState({backup_data:this.state.data}));
                
            }
            
        }
        else if(selectedTab === 'average_Fa_amount' ){
            if((this.state.facntryAvg && this.state.facntryAvg.length > 0) || (this.state.faAvg && this.state.faAvg.length > 0)){
    
                this.setState({
                    data: this.state.viewBy === true ? this.state.facntryAvg : this.state.faAvg,
                    chart_type: 'area',
                    colors:["#00e396", "#ff3154", "#fadd17",'#d989b5', '#8ee3cf', '#ff9f9e', '#ffe15d', '#8a5fc2', '#ffa07a', '#87cefa', '#90ee90', '#b0c4de', '#ff6347', '#c71585', '#20b2aa'],
                    xaxistype: 'category',
                    x_axis_format: this.state.dateWiseFilter ? true : undefined,
                    show_target:false,curr :activeCurrency,mixed_chart:false
                },()=>this.setState({backup_data:this.state.data}));
                
            }
            
        }
        else if(selectedTab === 'get_maximum_outstanding_fa' ){
            if (((this.state.MaximumOutstandingFA?.length > 0) || (this.state.MaximumOutstandingFAForCountry?.length > 0)) && !this.state.dateWiseFilter) {
                this.setState({
                    data: this.state.viewBy === true ? this.state.MaximumOutstandingFA :this.state.MaximumOutstandingFAForCountry,
                    chart_type: 'area',
                    xaxistype: 'category',
                    colors:["#00e396", "#ff3154", "#fadd17",'#d989b5', '#8ee3cf', '#ff9f9e', '#ffe15d', '#8a5fc2', '#ffa07a', '#87cefa', '#90ee90', '#b0c4de', '#ff6347', '#c71585', '#20b2aa'],
                    x_axis_format: this.state.dateWiseFilter ? true : undefined,
                    show_target:false,
                    curr :activeCurrency,mixed_chart:false
                },()=>this.setState({backup_data:this.state.data}));
            }
            
        } 
        else if(selectedTab === 'cohort_report' ){
            if (((this.state.cohortData && this.state.cohortData.length > 0) || (this.state.cohortDatasCount && this.state.cohortDatasCount.length > 0)) || (this.state.cohortPercentageDatas && this.state.cohortPercentageDatas.length >0 ) && !this.state.dateWiseFilter ) {
                this.setState({
                    data: (this.state.cohort_data_in_perc && !this.state.cohort_amount_type) ? this.state.cohortPercentageDatas : (this.state.cohort_amount_type) ? this.state.cohortDatasCount : this.state.cohortData,
                    chart_type: 'line',
                    xaxistype: 'category',
                    colors:["#00e396", "#fadd17",'#d989b5', '#8ee3cf', "#ff3154", '#ffe15d', '#8a5fc2', '#ffa07a', '#87cefa', '#90ee90', '#b0c4de', '#ff6347', '#c71585', '#20b2aa'],
                    x_axis_format: (this.state.report_type_is_weekly ? false : (this.state.cohort_data_in_perc && !this.state.cohort_amount_type) ? undefined : undefined),
                    curr:((this.state.cohort_data_in_perc && !this.state.cohort_amount_type) ? "%" : (this.state.cohort_amount_type) ? " " : activeCurrency),roundOff:false,mixed_chart:false
                },()=>this.setState({backup_data:this.state.data}));
                
            }
            
        } 
        else if(selectedTab === 'get_portfolio_chart' ){
            if ((this.state.original_row_datas && this.state.original_row_datas.length > 0) || (this.state.chart_data && this.state.chart_data > 0)) {
                this.setState({
                    data: (this.state.original_row_datas && this.state.original_row_datas.length > 0) ? this.state.original_row_datas : this.state.chart_data ,
                    chart_type: 'line',
                    xaxistype: 'category',
                    colors:["#00e396", "#ff3154", "#fadd17",'#d989b5', '#8ee3cf', '#ff9f9e', '#ffe15d', '#8a5fc2', '#ffa07a', '#87cefa', '#90ee90', '#b0c4de', '#ff6347', '#c71585', '#20b2aa'],
                    x_axis_format: (this.state.dateWiseFilter?true:undefined),
                    
                    roundOff:(this.state.req_perc_data ? false : this.state.roundOff),curr:(this.state.req_perc_data ? '%' : activeCurrency),mixed_chart:false
                },()=>this.setState({backup_data:this.state.data}));
                
            }
            
        }
        else if(selectedTab === 'overdues_recovered' ){
            if (this.state.OverduesRecoveredFAamount && this.state.OverduesRecoveredFAamount.length > 0) {
                this.setState({
                    data: ((!this.state.cohort_amount_type) ? ((this.state.report_type_is_weekly && !this.state.dateWiseFilter) ? this.state.OverduesRecoveredFAamount_week: this.state.OverduesRecoveredFAamount) : ((this.state.report_type_is_weekly && !this.state.dateWiseFilter) ? this.state.OverduesRecoveredFAcount_week: this.state.OverduesRecoveredFAcount)),
                    chart_type: 'area',
                    colors:["#00e396", "#ff3154", "#fadd17",'#d989b5', '#8ee3cf', '#ff9f9e', '#ffe15d', '#8a5fc2', '#ffa07a', '#87cefa', '#90ee90', '#b0c4de', '#ff6347', '#c71585', '#20b2aa'],
                    xaxistype: 'category',
                    x_axis_format: ((this.state.report_type_is_weekly && !this.state.dateWiseFilter) ? false : this.state.dateWiseFilter?true:undefined),
                    curr:(this.state.cohort_amount_type ? '' : activeCurrency),mixed_chart:false
                },()=>this.setState({backup_data:this.state.data}));
                
            }
            
        }
        else if(selectedTab === 'total_overdues' ){
            if (this.state.TotalOverduesFAamount && this.state.TotalOverduesFAcount.length > 0) {
                this.setState({
                    data: ((!this.state.cohort_amount_type) ? (this.state.TotalOverduesFAamount) : ( this.state.TotalOverduesFAcount)),
                    chart_type: 'area',
                    colors:["#00e396", "#ff3154", "#fadd17",'#d989b5', '#8ee3cf', '#ff9f9e', '#ffe15d', '#8a5fc2', '#ffa07a', '#87cefa', '#90ee90', '#b0c4de', '#ff6347', '#c71585', '#20b2aa'],
                    xaxistype: 'category',
                    x_axis_format: ((this.state.report_type_is_weekly && !this.state.dateWiseFilter) ? false : this.state.dateWiseFilter?true:undefined),
                    curr:(this.state.cohort_amount_type ? '' : activeCurrency),mixed_chart:false
                },()=>this.setState({backup_data:this.state.data}));
                
            }
            
        }
        else if(selectedTab === 'overdue_visits' ){
            if (this.state.OverdueVisitsFA && this.state.OverdueVisitsFA.length > 0) {
                this.setState({
                    data: (this.state.report_type_is_weekly && !this.state.dateWiseFilter) ? this.state.OverdueVisitsFA_week : this.state.OverdueVisitsFA,
                    chart_type: 'area',
                    colors:["#00e396", "#ff3154", "#fadd17",'#d989b5', '#8ee3cf', '#ff9f9e', '#ffe15d', '#8a5fc2', '#ffa07a', '#87cefa', '#90ee90', '#b0c4de', '#ff6347', '#c71585', '#20b2aa'],
                    xaxistype: 'category',
                    x_axis_format: (this.state.report_type_is_weekly ? false : this.state.dateWiseFilter?true:undefined),
                    curr:undefined,mixed_chart:false
                },()=>this.setState({backup_data:this.state.data}));
                
            }
            
        }
        if (selectedTab === 'get_monthly_cust_report'){
            this.setState({tab_loder_div:this.state.monthlyCustCntry})
        }
        else if(selectedTab === 'get_reg_and_active_customer'){
            this.setState({tab_loder_div:this.state.regAndActive})
        }
        else if(selectedTab === 'get_revenue_per_cust' ){
            this.setState({tab_loder_div:this.state.revpercust})
        }
        else if(selectedTab === 'get_revenue_per_fa' ){
            this.setState({tab_loder_div:this.state.revpercust})
        }
        else if(selectedTab === 'get_tot_disb_report' ){
            this.setState({tab_loder_div:this.state.totdisb})
        }
        else if(selectedTab === 'get_advances_disbursed_and_completed' ){
            this.setState({tab_loder_div:this.state.AmountDisbursedAndCompletedDiv})
        }
        else if(selectedTab === 'get_tot_settled_disb_count' ){
            this.setState({tab_loder_div:this.state.totalSettledDisbCountDiv})  
        }
        else if(selectedTab === 'get_ontime_payments' ){
            this.setState({tab_loder_div:this.state.ontimePaymentDiv})
        }
        else if(selectedTab === 'get_outstanding_fa' ){
            this.setState({tab_loder_div:this.state.outstandingFADiv})
        }
        else if(selectedTab === 'get_revenue' ){
            this.setState({tab_loder_div:this.state.revenueDiv})
        }
        else if(selectedTab === 'get_total_and_overdue_fa' ){
            this.setState({tab_loder_div:this.state.TotalAndOverdueFADiv})
        }
        else if(selectedTab === 'average_Fa_amount' ){
            this.setState({tab_loder_div:this.state.faAvgcntrydiv})
        }
        else if(selectedTab === 'get_maximum_outstanding_fa' ){
            this.setState({tab_loder_div:this.state.MaximumOutstandingFADiv})
        } 
        else if(selectedTab === 'total_overdues' ){
            this.setState({tab_loder_div:this.state.TotalOverdues})
        }
        else if(selectedTab === 'overdue_visits' ){
            this.setState({tab_loder_div:this.state.OverdueVisitsFADiv})
        }
        else if(selectedTab === 'overdues_recovered' ){
            this.setState({tab_loder_div:this.state.OverduesRecovered})
        }

    }
    
    componentWillMount()
    {
            if (get('role_codes') === 'investor') {
                this.setState({ normalendDate: moment().subtract(1, 'months').format("Y-MM"), normalstartDate: moment().subtract(7, 'months').format("Y-MM"),dateWiseStartDate:moment().subtract(2, 'months').format("Y-MM-DD"),dateWiseEndDate:moment().subtract(1, 'months').format("Y-MM-DD")  });
            }
            this.get_report_date();
            this.generate_report()
      
            this.setState({report_type_is_weekly: ( get('role_codes')== "investor") ? true:false})
            this.setState({role: ( get('role_codes')== "investor") ? true:false})
           
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate ){
            this.setState({filter_type:null})
        }
        if((prevState.country_code !== this.state.country_code) && this.state.dateWiseFilter){
            this.setState({filter_provider:null,filter_type:null,viewBy:false})
        }
        if( (prevState.country_code !== this.state.country_code) && !this.state.dateWiseFilter){
            this.setState({filter_provider:null,filter_type:null,viewBy:false})
        }
        if(!prevState.dateWiseFilter && this.state.dateWiseFilter){
            this.setState({filter_provider:null,filter_type:null,viewBy:false})
        }
        if(prevState.dateWiseFilter && !this.state.dateWiseFilter){
            this.setState({filter_provider:null,filter_type:null,viewBy:false})
        }

        if(prevState.pardayWise!=this.state.pardayWise){
        this.setState({acc_prvdr_codes:[],pardays:default_pardays},()=>this.filter_chart_data(this.state.chart_data)) 
        }
        if (
        (prevState.pardays !=this.state.pardays || prevState.acc_prvdr_codes != this.state.acc_prvdr_codes )&&
        this.state.chart_view &&
        this.state.chart_data_recieved
        ) {
        this.filter_chart_data(this.state.chart_data)
        
        }
       
        if(this.state.cohortDataDiv && ((prevState.startDate != this.state.startDate || prevState.endDate != this.state.endDate) || (prevState.country_code != this.state.country_code))){
            this.setState({cohortDataDiv:false,filter_provider:null,filter_type:null},()=>{ this.cohortReportCharts()})

        }
        if(this.state.cohortDataDiv && (prevState.activeCurrency != this.state.activeCurrency || prevState.activeCurrencyVal != this.state.activeCurrencyVal )){
            this.setState({cohortData : this.state.report_type_is_weekly ?this.convert_x_axis_data(this.state.cohortDataRaw,'currency'):  this.make_series_data( this.state.cohortDataRaw,'currency'),filter_provider:null,filter_type:null})

        }

        if(prevState.lender != this.state.lender){
            this.generate_report();
            this.get_gds_report()
            this.cohortReportCharts()
        }
        if((prevState.startDate !== this.state.startDate)|| (prevState.endDate !== this.state.endDate)){
            this.get_gds_report()
        }
        // if(prevState.viewBy != this.state.viewBy){
        //     if(!this.state.viewBy && this.stselectedTab == 'get_tot_settled_disb_count' ){
        //         this.handleButtonClick('advances_disbursed_and_completed')}
        // }
        if (prevState.country_code != this.state.country_code||prevState.dateWiseFilter!=this.state.dateWiseFilter ) {
            if(this.state.country_code == "*"){
                this.setState({viewBy : false,filter_provider:null,filter_type:null})
            }
            this.handleApiCall()
            this.setState({acc_prvdr_codes:[],isactive:false,current_lender_item:null,filter_provider:null,filter_type:null},()=>this.filter_chart_data(this.state.chart_data))
            // this.get_gds_report()
            // this.cohortReportCharts()
           // this.handleButtonClick()
            
            if(this.state.country_code=='*'){
                this.setState({pardays:default_pardays,pardayWise:true})
              }
           
            this.setState({dataProjections:false,par_chart:false,lender:null})
            this.generate_report();

        } 
        else if (prevState.activeCurrency != this.state.activeCurrency || prevState.activeCurrencyVal != this.state.activeCurrencyVal ) {
            if (this.state.revpercust && this.state.revPerFa && this.state.revenueDiv && this.state.revpercust && this.state.revpercustCntry &&
               this.state.revPerFa && this.state.revPerFaCntry && this.state.totdisb && this.state.totdisbCntry && this.state.revenueForCountryDiv&&this.state.faAvgdiv&&this.state.faAvgcntrydiv ){
        this.load_currency_charts(prevProps);
                    }if(this.state.chart_data){
                        this.filter_chart_data(this.state.chart_data)
                    }
        }else if (prevState.dateWiseEndDate != this.state.dateWiseEndDate ||prevState.dateWiseStartDate != this.state.dateWiseStartDate ||prevState.startDate != this.state.startDate || prevState.endDate != this.state.endDate||prevState.selectedMonth!=this.state.selectedMonth||prevState.selectedMonth!=this.state.selectedMonth||prevState.dataProjections!=this.state.dataProjections){
            this.reload_charts(prevProps)
            
        }else if(prevState.isactive!=this.state.isactive){
            this.setState({dataProjections:false})

        }else if((this.state.report_type_is_weekly != prevState.report_type_is_weekly)  && this.state.selectedTab === 'cohort_report'){
         
            this.setState({cohortDataDiv:false,data:false,tab_loder_div:false},()=>{ this.cohortReportCharts()})
        }

        
        
    }

    // componentDidMount() {
    //     React.createElement("Script",{},"$('.carousel').carousel({\n" +
    //         "  interval: 1000\n" +
    //         "})")
    // }
    toggleDropdown = () => {
        this.setState(prevState => ({
          dropdownOpen: !prevState.dropdownOpen
        }));
      }

      handleCheckboxClick = (e) => {
        const { selectedItems } = this.state;
        const { value } = e.target;
    
        if (selectedItems.includes(value)) {
          this.setState({
            selectedItems: selectedItems.filter(item => item !== value)
          },()=>this.reportChartData());
        } else {
          this.setState({
            selectedItems: [...selectedItems, value]
          },()=>this.reportChartData());
        }
      }

      handleproviderWise = (val) =>{

        this.setState({acc_prvdr_codes:[],filter_provider:null},()=>this.filter_chart_data(this.state.chart_data))
        
          this.setState({'pardayWise' : !this.state.pardayWise,},()=>{
            if(!this.state.pardayWise){
              this.setState({pardays:default_pardays},()=>this.filter_chart_data(this.state.chart_data))
            }
          });
      
      }
    get_gds_report=async(filter = null )=>{
        this.setState({loader:true,chart_data_recieved:false,original_row_datas :[],pardays:[]})
        var request = {
          "show_chart":true,
          "date":this.state.setDate,
          "time_zone":get('market').time_zone,
          "country_code":this.state.country_code, 
          "acc_prvdr_code":this.state.gds_report_active_accPrvds,
          "report_type":'get_portfolio_chart',
          "start_date":moment(this.state.startDate).format('YYYY-MM-DD'),
          "end_date":moment(this.state.endDate).format('YYYY-MM-DD'),
          "req_perc_data":this.state.req_perc_data,
          "lender":this.state.lender,
          "filter_type":this.state.filter_type
        }
        await getReport(request)
        .then((response)=>{
          
          if(!response){
            this.setState({no_data:true,loader:false})
            return }
          if(response.status=="success")
          {  
            this.setState({no_data:false})
            if(this.state.chart_view){
              this.setState({original_row_datas:response.data,chart_data:response.data},()=>this.setState({loader:false},  ()=>this.filter_chart_data(this.state.chart_data)))
            }
            else{
              this.setState({original_row_datas:response.data.report, report_date : response.data.report_date},()=>this.reportChartData(), this.accprvds_handler)
              this.setState({loader:false})
            }
         }
         else{
            this.setState({tab_loder_div:true})
         }
        })
      }
      generate_report = () => {
        
        var val = this.state.selectedValue;
        var req = {'country_code' : this.state.country_code, 'currency_code' : this.state.active_currency, 'dateWiseFilter':this.state.dateWiseFilter}
        req.lender = this.state.lender;
        req.filter_type = this.state.filter_type;
        req.filter_provider = this.state.filter_provider;
        req.chart_weekly = this.state.chart_weekly;
        req.report_type = "get_monthly_cust_report";
        this.handlemMonthlyCustReport(req);
        req.report_type = "get_monthly_cust_report_of_country";
        this.handlemMonthlyCustReportOfCountry(req);
        req.report_type="get_target";
        this.handleTarget(req);
        req.report_type = "get_reg_and_active_customer";
        this.handleRegAndActiveCustReport(req);
        req.report_type = "get_revenue_for_country";
        this.handleRevenueForCountryReport(req);
        req.report_type = "get_revenue_per_cust";
        this.handleRevenuePerCustReport(req);
        req.report_type = "get_revenue_per_cust_of_country";
        this.handleRevenuePerCustReportForCountry(req);
        req.report_type = "get_revenue_per_fa";
        this.handleRevenuePerFAReport(req);
        req.report_type = "get_revenue_per_fa_of_country";
        this.handleRevenuePerFAReportOfCountry(req);
        req.report_type = "get_tot_disb_report";
        this.handleTotDisbursalReport(req);
        req.report_type = "get_tot_disb_report_of_country";
        this.handleTotDisbursalReportOfCountry(req);
        // req.report_type = "get_tot_disb_count";
        // this.handleTotalDisbCountReport(req);
        // req.report_type = "get_tot_disb_count_of_country";
        // this.handleTotalDisbCountReportOfCountry(req);
        req.report_type = "get_tot_settled_disb_count";
        this.handleTotalSettledDisbCountReport(req);
        req.report_type = "get_tot_settled_disb_count_of_country";
        this.handleTotalSettledDisbCountReportForCountry(req);
        req.report_type = "get_ontime_payments_for_country";
        this.handleOntimePaymentForCountryReport(req);
        req.report_type = "get_ontime_payments";
        this.handleOntimePaymentReport(req);
        req.report_type = "get_outstanding_fa_for_country";
        this. handleOutstandingFAForCountryReport(req)
        req.report_type = "get_outstanding_fa";
        this. handleOutstandingFAReport(req)
        req.report_type = "get_revenue";
        this.handleRevenueReport(req);
        req.report_type = "get_revenue_for_country";
        this.handleRevenueForCountryReport(req);
        req.report_type = "get_total_and_overdue_fa";
        this.handleTotalAndOverdueFAReport(req)
        req.report_type="get_advances_disbursed_and_completed";
        this.handleAdvancesDisbursedAndCompleted(req);
        req.report_type="average_Fa_amount";
        this.handleAverageFaAmount(req);
        req.report_type="average_Fa_amount_for_country";
        this.handleAverageFaAmountForCountry(req); 
        req.report_type = "get_maximum_outstanding_fa";
        this.handleMaximumOutstandingFAReport(req)
        req.report_type = "get_maximum_outstanding_fa_for_country";
        this.handleMaximumOutstandingFAForCountryReport(req)
        req.report_type = "overdue_visits"
            this.OverdueVisits(req)
        req.report_type = "overdues_recovered"
            this.OverduesRecovered(req)
        req.report_type = "total_overdues"
            this.TotalOverdues(req)
      }
   


    set_attributes = (array) =>
    {
        if(this.state.country_code != array.country_code || this.state.activeCurrency != array.activeCurrency || this.state.startDate != array.startDate || this.state.endDate != array.endDate  || this.state.activeCurrencyVal != array.activeCurrencyVal ||this.state.dateWiseFilter != array.dateWiseFilter || this.state.lender != array.lender ){
            this.setState(array)
            if (["get_portfolio_chart","get_advances_disbursed_and_completed", "get_total_and_overdue_fa","get_reg_and_active_customer"].some(tab => this.state.selectedTab.includes(tab))){
                this.setState({data:false,tab_loder_div:false})
            }
            else{
                this.setState({data:false,tab_loder_div:false,filter_provider:null})
            }
            
        }
    }

    make_series_data_week(data, type) {
        var series = [];
        const dateWiseFilter = this.state.dateWiseFilter || false;
        let startDate = moment(this.state.startDate || "2018-01-01");
        let endDate = moment(this.state.endDate || moment());
        if (!dateWiseFilter) {
            startDate = startDate.startOf('week');
            endDate = endDate.endOf('week');
        }
    
        let filteredData = {};
    
        Object.keys(data || {}).forEach(key => {
            if (!data[key]) return;
            filteredData[key] = [];
    
            data[key].forEach(item => {
                if (!item || !item.x) {
                    return; 
                }
                try {
                    const weekDate = moment(item.x, "YYYY-MMM-wX");
                    if (weekDate.isValid() && weekDate.isBetween(startDate, endDate, null, '[]')) {
                        filteredData[key].push(item);
                    }
                } catch (error) {
                    return; 
                }
            });
    
            if (filteredData[key].length === 0) {
                delete filteredData[key];
            }
        });
    
        Object.keys(filteredData).forEach(key => {
            let seriesData = {
                name: key === '*' ? 'Global' : key,
                data: filteredData[key]
            };
            series.push(seriesData);
        });
        return series;
    }
    
    
    
    
    
    
    

   
    make_series_data =  (array,type,addl_type=null)=>
    {
        var series = [];
        const curVal = this.state.activeCurrencyVal == '' ? 1 : this.state.activeCurrencyVal
        const code = this.state.country_code = '' ? '*' : this.state.country_code 
        const selected_tab = this.state.selectedTab
        const role =this.state.role
        var orginal_data = array?JSON.parse(JSON.stringify(array)):{};
        var data_arr = []
        var startDate = this.state.startDate
        var endDate = this.state.endDate
        var dateWiseFilter=this.state.dateWiseFilter;
        if ((startDate !== "" || endDate !== "" )) {
            if(startDate === ""){
                startDate = moment("2018-01-01").format();
            }else {
                if(dateWiseFilter){
                    startDate= moment(startDate).format();
                }
                else{
                    startDate= moment(startDate).startOf('month').format();

                }
            }
            if(endDate === ""){
                endDate = moment().format()
            }else{
                if(dateWiseFilter){
                    endDate= moment(endDate).format();
                }
                else{
                    endDate= moment(endDate).endOf('month').format();
                }
            }
            Object.keys(orginal_data).map(function(key) {
                data_arr[key] = [];
                orginal_data[key].map(function (each, idx) {
                    let crnt_date = moment(orginal_data[key][idx]['x'])
                    if(  (dateWiseFilter && moment(crnt_date).isBetween(startDate, endDate, null, '[]')) || 

                    (!dateWiseFilter &&(moment(startDate).isBefore(crnt_date) &&  moment(endDate).endOf("month").isAfter(crnt_date)) 
                    || 
                    moment(startDate).isSame(crnt_date, 'date') || moment(endDate).isSame(crnt_date, 'date') )) {
                        data_arr[key].push(orginal_data[key][idx])
                    }
                  
                })
            })
        }
        else{
            data_arr = orginal_data
        }
        Object.keys(data_arr).map(function(key){
            var series_data = {};
            if(key!="*"){
                series_data['name'] = key
                if(!role){
                    if (key.indexOf("Total Disbursed") === 0) {
                        series_data['type'] = 'bar';
                    }
                    else if (key.indexOf("New Overdues") === 0 || key.indexOf("Current Overdues") === 0) {
                        series_data['type'] = 'line';
                    }
                }
            }else{
                series_data['name'] = "Global"
            }
            
            if(type == 'currency' && key != '*' && code != '*'){
                data_arr[key].map(function (each,idx){
                    if(idx != "type"){
                        data_arr[key][idx]['y'] = data_arr[key][idx]['y'] * curVal
                    }
                })
            }
            series_data['data'] = data_arr[key]
            if("type" in data_arr[key]){
                data_arr[key][idx]['y'] = (data_arr[key][idx]['y'] * curVal).toFixed(2);
            }
            series.push(series_data)
        });
        return series;
    }
    
   

    get_report_date = () => {
        getReportDate(this.req()).then(
            (response) => {
                this.resp(response)

                if(!response) {return;}
                if(response.status === "success") {
                    let reportDate = response.data
                    reportDate = moment(reportDate).format("DD MMM YYYY")
                    this.setState({reportDate})
                }
                }
        )

    }

    load_currency_charts = (prevProps) =>{
        if(prevProps.selectedTab !== this.state.selectedTab ){
        this.setState({
            revenuePerCustomer: this.make_series_data(this.state.revenuePerCustomerRaw, 'currency'),
            revenuePerFa: this.make_series_data(this.state.revenuePerFaRaw, 'currency'),
            totDisbursal: this.make_series_data(this.state.totDisbursalRaw, 'currency'),
            revenueForCountry: this.make_series_data(this.state.dataProjections?this.toCalculateProjection(this.state.revenueForCountryRaw):this.state.revenueForCountryRaw, 'currency'),
            revenuePerCustomerForCountry: this.make_series_data(this.state.revenuePerCustomerRawForCountry, 'currency'),
            revenuePerFaForCountry: this.make_series_data(this.state.revenuePerFaRawForCountry, 'currency'),
            totDisbursalForCountry: this.make_series_data(this.state.totDisbursalRawForCountry, 'currency'),
            revenue: this.make_series_data(this.state.dataProjections?this.toCalculateProjection(this.state.revenueRaw):this.state.revenueRaw, 'currency'),
            ontimePayment : this.make_series_data(this.state.ontimePaymentRaw),
            outstandingFA : this.make_series_data(this.state.outstandingFARaw,'currency'),
            outstandingFAForCountry: this.make_series_data(this.state.outstandingFAForCountryRaw, 'currency'),
            faAvg : this.make_series_data(this.state.faAvgRaw,'currency'),
            facntryAvg : this.make_series_data(this.state.faAvgcntryRaw,"currency"),
            MaximumOutstandingFA : this.make_series_data(this.state.MaximumOutstandingFARaw,'currency'),
            MaximumOutstandingFAForCountry: this.make_series_data(this.state.MaximumOutstandingFAForCountryRaw,'currency')

        },()=>this.reportChartData());
        }
    }

    reload_charts = (prevProps) => { 
        if(prevProps.selectedTab !== this.state.selectedTab ){
        this.setState({
            monthlyCustReport : this.make_series_data(this.state.monthlyCustReportRaw),
            monthlyCustReportofCountry : this.make_series_data(this.state.monthlyCustReportRawofCountry),
            regAndActiveCust : this.make_series_data(this.state.dataProjections?this.toCalculateProjection(this.state.regAndActiveCustRaw,"cummulative"):this.state.regAndActiveCustRaw ),
            revenuePerCustomer: this.make_series_data(this.state.revenuePerCustomerRaw, 'currency'),
            revenuePerFa: this.make_series_data(this.state.revenuePerFaRaw, 'currency'),
            totDisbursal: this.make_series_data(this.state.totDisbursalRaw, 'currency'),
            revenueForCountry: this.make_series_data(this.state.dataProjections?this.toCalculateProjection(this.state.revenueForCountryRaw):this.state.revenueForCountryRaw, 'currency'),
            revenuePerCustomerForCountry: this.make_series_data(this.state.revenuePerCustomerRawForCountry, 'currency'),
            revenuePerFaForCountry: this.make_series_data(this.state.revenuePerFaRawForCountry, 'currency'),
            totDisbursalForCountry: this.make_series_data(this.state.totDisbursalRawForCountry, 'currency'),
            totalDisbCount : this.make_series_data(this.state.totalDisbCountRaw),
            totalDisbCountForCountry : this.make_series_data(this.state.totalDisbCountRawForCountry),
            revenue: this.make_series_data(this.state.dataProjections?this.toCalculateProjection(this.state.revenueRaw):this.state.revenueRaw, 'currency'),
            totalSettledDisbCount : this.make_series_data(this.state.totalSettledDisbCountRaw),
            totalSettledDisbCountForCountry : this.make_series_data(this.state.totalSettledDisbCountRawForCountry),
            ontimePaymentForCountry : this.make_series_data(this.state.ontimePaymentForCountryRaw, "percentage"),
            TotalAndOverdueFA : this.make_series_data(this.state.TotalAndOverdueFARaw),
            outstandingFA : this.make_series_data(this.state.outstandingFARaw,'currency'),
            outstandingFAForCountry: this.make_series_data(this.state.outstandingFAForCountryRaw, 'currency'),
            AmountDisbursedAndCompleted : this.make_series_data(this.state.AmountDisbursedAndCompletedRaw),
            faAvg : this.make_series_data(this.state.faAvgRaw,"currency"),
            facntryAvg: this.make_series_data(this.state.faAvgcntryRaw,"currency"),
            MaximumOutstandingFA : this.make_series_data(this.state.MaximumOutstandingFARaw,'currency'),
            MaximumOutstandingFAForCountry: this.make_series_data(this.state.MaximumOutstandingFAForCountryRaw,'currency'),
            OverdueVisitsFA : this.make_series_data(this.state.OverdueVisitsFARaw.od_rm_visits),
            OverdueVisitsFA_week :(!this.state.dateWiseFilter ? this.make_series_data_week(this.state.OverdueVisitsFARaw.od_rm_visits_week) : ""),
            OverduesRecoveredFAamount: this.make_series_data(this.state.OverduesRecoveredFARaw.od_rec_amount,'currency'),
            OverduesRecoveredFAcount:this.make_series_data(this.state.OverduesRecoveredFARaw.od_rec_count),
            OverduesRecoveredFAamount_week: (!this.state.dateWiseFilter ? this.make_series_data_week(this.state.OverduesRecoveredFARaw.od_rec_amount_week,'currency'): ""),
            OverduesRecoveredFAcount_week: (!this.state.dateWiseFilter ?this.make_series_data_week(this.state.OverduesRecoveredFARaw.od_rec_count_week): ""),
            TotalOverduesFAamount:this.make_series_data(this.state.TotalOverduesFARaw.od_amount,'currency'),
            TotalOverduesFAcount:this.make_series_data(this.state.TotalOverduesFARaw.od_count),
            TotalOverduesFAamount_week: (!this.state.dateWiseFilter ? this.make_series_data_week(this.state.TotalOverduesFARaw.od_amount_week,'currency'): ""),
            TotalOverduesFAcount_week: (!this.state.dateWiseFilter ? this.make_series_data_week(this.state.TotalOverduesFARaw.od_count_week): ""),

        },()=>this.reportChartData());
    }
    }

    handlemMonthlyCustReport = (event)=>
    {this.setState({monthlyCust:false,monthlyCustReport:[],monthlyCustReportRaw:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const monthlyCustReportRaw = response.data
                    const monthlyCustReport = this.make_series_data(monthlyCustReportRaw);
                    
                    this.setState({monthlyCustReportRaw,monthlyCustReport,monthlyCust:true
                    }, () => {
                        if (this.state.selectedTab === 'get_monthly_cust_report') {
                            this.reportChartData();
                        }});
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            })
    }
    handleTarget = (event)=>
    {this.setState({targetRaw:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return;}
                if(response.status === "success"){
                    const targetRaw = response.data
                    this.setState({targetRaw},()=>this.reportChartData())
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }

    handlemMonthlyCustReportOfCountry = (event)=>
    {this.setState({monthlyCustCntry:false,monthlyCustReportofCountry:[],monthlyCustReportRawofCountry:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const monthlyCustReportRawofCountry = response.data
                    const monthlyCustReportofCountry = this.make_series_data(monthlyCustReportRawofCountry);
                    this.setState({monthlyCustReportRawofCountry,monthlyCustReportofCountry,monthlyCustCntry:true
                    }, () => {
                        if (this.state.selectedTab === 'get_monthly_cust_report') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }

    handleRegAndActiveCustReport = (event)=>
    {this.setState({regAndActive:false,regAndActiveCust:[],regAndActiveCustRaw:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const regAndActiveCustRaw = response.data
                    const regAndActiveCust = this.make_series_data(regAndActiveCustRaw);
                    this.setState(
                        { regAndActiveCustRaw, regAndActiveCust },
                        () => {
                            this.setState(
                                { regAndActive: true },
                                () => {
                                    if (this.state.selectedTab === 'get_reg_and_active_customer') {
                                        this.reportChartData();
                                    }}
                            );
                        }
                    );
                }else{

                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            })
    
    }

    handleRevenuePerCustReport = (event)=>
    {this.setState({revpercust:false,revenuePerCustomer:[],revenuePerCustomerRaw:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const revenuePerCustomerRaw = response.data
                    const revenuePerCustomer = this.make_series_data(response.data, 'currency');
                    this.setState({revenuePerCustomerRaw,revenuePerCustomer,revpercust:true
                    }, () => {
                        if (this.state.selectedTab === 'get_revenue_per_cust') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }

    handleRevenuePerCustReportForCountry = (event)=>
    {this.setState({revpercustCntry:false,revenuePerCustomerForCountry:[],revenuePerCustomerRawForCountry:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const revenuePerCustomerRawForCountry = response.data
                    const revenuePerCustomerForCountry = this.make_series_data(response.data, 'currency');
                    this.setState({revenuePerCustomerRawForCountry,revenuePerCustomerForCountry,revpercustCntry:true
                    }, () => {
                        if (this.state.selectedTab === 'get_revenue_per_cust') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            })
    
    }
    

    handleRevenuePerFAReport = (event)=>
    {this.setState({revPerFa:false,revenuePerFa:[],revenuePerFaRaw:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const revenuePerFaRaw = response.data
                    const revenuePerFa = this.make_series_data(revenuePerFaRaw, "currency");
                    this.setState({revenuePerFaRaw,revenuePerFa,revPerFa:true
                    }, () => {
                        if (this.state.selectedTab === 'get_revenue_per_fa') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }

    handleRevenuePerFAReportOfCountry = (event)=>
    {this.setState({revPerFaCntry:false,revenuePerFaForCountry:[],revenuePerFaRawForCountry:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const revenuePerFaRawForCountry = response.data
                    const revenuePerFaForCountry = this.make_series_data(revenuePerFaRawForCountry, "currency");
                    this.setState({revenuePerFaRawForCountry,revenuePerFaForCountry,revPerFaCntry:true
                    }, () => {
                        if (this.state.selectedTab === 'get_revenue_per_fa') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }

    handleTotDisbursalReport = (event)=>
    {this.setState({totdisb:false,totDisbursal:[],totDisbursalRaw:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const totDisbursalRaw = response.data
                    const totDisbursal = this.make_series_data(totDisbursalRaw, "currency");
                    this.setState({totDisbursalRaw,totDisbursal,totdisb:true
                    }, () => {
                        if (this.state.selectedTab === 'get_tot_disb_report') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }

    handleTotDisbursalReportOfCountry = (event)=>
    {this.setState({totdisbCntry:false,totDisbursalForCountry:[],totDisbursalRawForCountry:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const totDisbursalRawForCountry = response.data
                    const totDisbursalForCountry = this.make_series_data(totDisbursalRawForCountry, "currency");
                    this.setState({totDisbursalRawForCountry,totDisbursalForCountry,totdisbCntry:true
                    }, () => {
                        if (this.state.selectedTab === 'get_tot_disb_report') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }

    handleTotalDisbCountReport = (event)=>
    {this.setState({totalDisbCountDiv:false,totalDisbCount:[],totalDisbCountRaw:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const totalDisbCountRaw = response.data
                    const totalDisbCount = this.make_series_data(totalDisbCountRaw);
                    this.setState({totalDisbCountRaw,totalDisbCount,totalDisbCountDiv:true
                    }, () => {
                        if (this.state.selectedTab === 'get_tot_disb_count') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }

    handleTotalDisbCountReportOfCountry = (event)=>
    {this.setState({totalDisbCountDivCntry:false,totalDisbCountForCountry:[],totalDisbCountRawForCountry:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const totalDisbCountRawForCountry = response.data
                    const totalDisbCountForCountry = this.make_series_data(totalDisbCountRawForCountry);
                    this.setState({totalDisbCountRawForCountry,totalDisbCountForCountry,totalDisbCountDivCntry:true
                    }, () => {
                        if (this.state.selectedTab === 'get_tot_disb_count_of_country') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }

    handleTotalSettledDisbCountReport = (event)=>
    {this.setState({totalSettledDisbCountDiv:false,totalSettledDisbCount:[],totalSettledDisbCountRaw:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const totalSettledDisbCountRaw = response.data
                    const totalSettledDisbCount = this.make_series_data(totalSettledDisbCountRaw);
                    this.setState({totalSettledDisbCountRaw,totalSettledDisbCount,totalSettledDisbCountDiv:true
                    }, () => {
                        if (this.state.selectedTab === 'get_tot_settled_disb_count') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }

    handleTotalSettledDisbCountReportForCountry = (event)=>
    {this.setState({totalSettledDisbCountDivCntry:false,totalSettledDisbCountForCountry:[],totalSettledDisbCountRawForCountry:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const totalSettledDisbCountRawForCountry = response.data
                    const totalSettledDisbCountForCountry = this.make_series_data(totalSettledDisbCountRawForCountry);
                    this.setState({totalSettledDisbCountRawForCountry,totalSettledDisbCountForCountry,totalSettledDisbCountDivCntry:true
                    }, () => {
                        if (this.state.selectedTab === 'get_tot_settled_disb_count') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }

    handleOntimePaymentForCountryReport = (event)=>
    {this.setState({ontimePaymentForCountryDiv:false,ontimePaymentForCountry:[],ontimePaymentForCountryRaw:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const ontimePaymentForCountryRaw = response.data
                    const ontimePaymentForCountry = this.make_series_data(ontimePaymentForCountryRaw, "percentage");
                    this.setState({ontimePaymentForCountryRaw,ontimePaymentForCountry,ontimePaymentForCountryDiv:true
                    }, () => {
                        if (this.state.selectedTab === 'get_ontime_payments') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }


    handleOntimePaymentReport = (event)=>
    {this.setState({ontimePaymentDiv:false,ontimePayment:[],ontimePaymentRaw:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const ontimePaymentRaw = response.data
                    const ontimePayment = this.make_series_data(ontimePaymentRaw, "percentage");
                    this.setState({ontimePaymentRaw,ontimePayment,ontimePaymentDiv:true
                    }, () => {
                        if (this.state.selectedTab === 'get_ontime_payments') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }

    handleMaximumOutstandingFAForCountryReport =(event)=>{
        this.setState({MaximumOutstandingFAForCountryDiv:false,MaximumOutstandingFAForCountry:[],MaximumOutstandingFAForCountryRaw:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const MaximumOutstandingFAForCountryRaw = response.data
                    const MaximumOutstandingFAForCountry = this.make_series_data(MaximumOutstandingFAForCountryRaw,"currency");
                    this.setState({MaximumOutstandingFAForCountryRaw,MaximumOutstandingFAForCountry,MaximumOutstandingFAForCountryDiv:true
                    }, () => {
                        if (this.state.selectedTab === 'get_maximum_outstanding_fa') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    
    }


    handleOutstandingFAForCountryReport = (event)=>
    {this.setState({outstandingFAForCountryDiv:false,outstandingFAForCountry:[],outstandingFAForCountryRaw:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const outstandingFAForCountryRaw = response.data
                    const outstandingFAForCountry = this.make_series_data(outstandingFAForCountryRaw,"currency");
                    this.setState({outstandingFAForCountryRaw,outstandingFAForCountry,outstandingFAForCountryDiv:true
                    }, () => {
                        if (this.state.selectedTab === 'get_outstanding_fa') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }
    OverdueVisits=(event)=>{
      this.setState({OverdueVisitsFADiv:false,OverdueVisitsFA:[],OverdueVisitsFARaw :[],OverdueVisitsFA_Week : []})
      getReport(this.req(event))
          .then((response) => {
              if(!response) {return ;}
              if(response.status === "success"){
                  const OverdueVisitsFARaw = response.data
                  const OverdueVisitsFA = this.make_series_data(OverdueVisitsFARaw.od_rm_visits);
                  const OverdueVisitsFA_week = (!this.state.dateWiseFilter ? this.make_series_data_week(OverdueVisitsFARaw.od_rm_visits_week): "");
                  this.setState({OverdueVisitsFARaw,OverdueVisitsFA,OverdueVisitsFA_week,OverdueVisitsFADiv:true
                  }, () => {
                    if (this.state.selectedTab === 'overdue_visits') {
                        this.reportChartData();
                    }})
              }else{
                  alert(response.message + "\n" + JSON.stringify(response.data));
                  this.setState({tab_loder_div:true})
              }
          });
    }

    OverduesRecovered=(event)=>{
      this.setState({OverduesRecovered:false,OverduesRecoveredFAamount:[],OverduesRecoveredFARaw :[],OverduesRecoveredFAcount:[],OverduesRecoveredFAamount_week:[],OverduesRecoveredFAcount_week:[]})
      getReport(this.req(event))
          .then((response) => {
              if(!response) {return ;}
              if(response.status === "success"){
                  const OverduesRecoveredFARaw = response.data
                  const OverduesRecoveredFAamount= this.make_series_data(OverduesRecoveredFARaw.od_rec_amount,'currency');
                  const OverduesRecoveredFAcount= this.make_series_data(OverduesRecoveredFARaw.od_rec_count);
                  const OverduesRecoveredFAamount_week= (!this.state.dateWiseFilter ? this.make_series_data_week(OverduesRecoveredFARaw.od_rec_amount_week,'currency'): "");
                  const OverduesRecoveredFAcount_week= (!this.state.dateWiseFilter ? this.make_series_data_week(OverduesRecoveredFARaw.od_rec_count_week): "");
                  this.setState({OverduesRecoveredFARaw,OverduesRecoveredFAamount,OverduesRecoveredFAcount,OverduesRecovered:true,OverduesRecoveredFAamount_week,OverduesRecoveredFAcount_week
                  }, () => {
                    if (this.state.selectedTab === 'overdues_recovered') {
                        this.reportChartData();
                    }})
              }else{
                  alert(response.message + "\n" + JSON.stringify(response.data));
                  this.setState({tab_loder_div:true})
              }
          });
    }

    TotalOverdues=(event)=>{
      this.setState({TotalOverdues:false,TotalOverduesFA:[],TotalOverduesFARaw :[]})
      this.setState({TotalOverdues:false,TotalOverduesFAamount:[],TotalOverduesFARaw :[],TotalOverduesFAcount:[],TotalOverduesFAamount_week:[],TotalOverduesFAcount_week:[]})
      getReport(this.req(event))
          .then((response) => {
              if(!response) {return ;}
              if(response.status === "success"){
                    const TotalOverduesFARaw = response.data
                    const TotalOverduesFAamount= this.make_series_data(TotalOverduesFARaw.od_amount,'currency');
                    const TotalOverduesFAcount= this.make_series_data(TotalOverduesFARaw.od_count);
                    const TotalOverduesFAamount_week= (!this.state.dateWiseFilter ? this.make_series_data_week(TotalOverduesFARaw.od_amount_week,'currency'): "");
                    const TotalOverduesFAcount_week= (!this.state.dateWiseFilter ? this.make_series_data_week(TotalOverduesFARaw.od_count_week): "");
                    this.setState({TotalOverduesFARaw,TotalOverduesFAamount,TotalOverduesFAcount,TotalOverdues:true,TotalOverduesFAamount_week,TotalOverduesFAcount_week
                  }, () => {
                    if (this.state.selectedTab === 'total_overdues') {
                        this.reportChartData();
                    }})
              }else{
                  alert(response.message + "\n" + JSON.stringify(response.data));
                  this.setState({tab_loder_div:true})
              }
          });
    }

    handleMaximumOutstandingFAReport=(event)=>{
        this.setState({MaximumOutstandingFADiv:false,MaximumOutstandingFA:[],MaximumOutstandingFARaw :[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const MaximumOutstandingFARaw = response.data
                    const MaximumOutstandingFA = this.make_series_data(MaximumOutstandingFARaw,"currency");
                    this.setState({MaximumOutstandingFARaw,MaximumOutstandingFA,MaximumOutstandingFADiv:true
                    }, () => {
                        if (this.state.selectedTab === 'get_maximum_outstanding_fa') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    
        }

    handleOutstandingFAReport = (event)=>
    {this.setState({outstandingFADiv:false,outstandingFA:[],outstandingFARaw :[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const outstandingFARaw = response.data
                    const outstandingFA = this.make_series_data(outstandingFARaw,"currency");
                    this.setState({outstandingFARaw,outstandingFA,outstandingFADiv:true
                    }, () => {
                        if (this.state.selectedTab === 'get_outstanding_fa') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }

 
    handleRevenueForCountryReport = (event)=>
    {this.setState({revenueForCountryDiv:false,revenueForCountry:[],revenueForCountryRaw:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const revenueForCountryRaw = response.data
                    const revenueForCountry = this.make_series_data(revenueForCountryRaw, "currency");
                    this.setState({revenueForCountryRaw,revenueForCountry,
                    revenueForCountryDiv:true}, () => {
                        if (this.state.selectedTab === 'get_revenue') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }

    handleRevenueReport = (event)=>
    {this.setState({revenueDiv:false,revenue:[],revenueRaw:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const revenueRaw = response.data
                    const revenue = this.make_series_data(revenueRaw, "currency");
                    this.setState({revenueRaw,revenue
                    ,revenueDiv:true}, () => {
                        if (this.state.selectedTab === 'get_revenue') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }

    handleTotalAndOverdueFAReport = (event)=>
    {this.setState({TotalAndOverdueFADiv:false,TotalAndOverdueFA:[],TotalAndOverdueFARaw:[]})
        getReport(this.req(event))
            .then((response) => {
                if(!response) {return ;}
                if(response.status === "success"){
                    const TotalAndOverdueFARaw = response.data
                    const TotalAndOverdueFA = this.make_series_data(TotalAndOverdueFARaw);
                    this.setState({TotalAndOverdueFARaw,TotalAndOverdueFA,TotalAndOverdueFADiv:true
                    }, () => {
                        if (this.state.selectedTab === 'get_total_and_overdue_fa') {
                            this.reportChartData();
                        }})
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({tab_loder_div:true})
                }
            });
    }

    handleAdvancesDisbursedAndCompleted=(event)=>{
        this.setState({AmountDisbursedAndCompletedDiv:false,AmountDisbursedAndCompleted:[],AmountDisbursedAndCompletedRaw:[]})
        getReport(this.req(event))
        .then((response) => {
            this.resp(response)
            if(!response) {return ;}
            if(response.status === "success"){
                const AmountDisbursedAndCompletedRaw = response.data
                const AmountDisbursedAndCompleted = this.make_series_data(AmountDisbursedAndCompletedRaw);
                this.setState({AmountDisbursedAndCompletedRaw,AmountDisbursedAndCompleted,AmountDisbursedAndCompletedDiv:true
                }, () => {
                    if (this.state.selectedTab === 'get_advances_disbursed_and_completed') {
                        this.reportChartData();
                    }})
            }else{
                alert(response.message + "\n" + JSON.stringify(response.data));
                this.setState({tab_loder_div:true})
            }
        });

    }
    handleAverageFaAmount=(event)=>{
        this.setState({faAvgdiv:false,faAvg:[],faAvgRaw:[]})
        getReport(this.req(event))
        .then((response) => {
            if(!response) {return ;}
            if(response.status === "success"){
                const faAvgRaw = response.data
                const faAvg = this.make_series_data(faAvgRaw,'currency');
                this.setState({faAvgRaw,faAvg,faAvgdiv:true}, () => {
                    if (this.state.selectedTab === 'average_Fa_amount') {
                        this.reportChartData();
                    }})
            }else{
                alert(response.message + "\n" + JSON.stringify(response.data));
                this.setState({tab_loder_div:true})
            }
        });

    }
    handleAverageFaAmountForCountry=(event)=>{
        this.setState({faAvgcntrydiv:false,facntryAvg:[],faAvgcntryRaw:[]})
        getReport(this.req(event))
        .then((response) => {
            if(!response) {return ;}
            if(response.status === "success"){
                const faAvgcntryRaw = response.data
                const facntryAvg = this.make_series_data(faAvgcntryRaw,'currency');
                this.setState({faAvgcntryRaw,facntryAvg,faAvgcntrydiv:true}, () => {
                    if (this.state.selectedTab === 'average_Fa_amount') {
                        this.reportChartData();
                    }})
            }else{
                alert(response.message + "\n" + JSON.stringify(response.data));
                this.setState({tab_loder_div:true})
            }
        });

    }
    get_prvdr_data = (key, value) => {
        const original_data = this.state.backup_data;
        if (original_data) {
          const filteredData = original_data.find(item => item[key] === value);
          if (filteredData) {
            let series = [];
            Object.keys(filteredData).forEach(dataKey => {
              if (dataKey === key) {
                let seriesData = {
                  name: dataKey === '*' ? 'Global' : filteredData[dataKey],
                  data: filteredData.data
                };
                series.push(seriesData);
              }
            });
            this.setState({data:series,filter_provider:value});
          } else {
            this.setState({ data: false });
          }
        }
      }
      
      acc_prvdr_changed = (val) => {
        if (val === 'All'){
            val = null
        }
        const { selectedTab, filter_provider, filter_type, chart_view, backup_data } = this.state;
        if (!filter_type && val !== filter_provider) {
          if (
            ["get_advances_disbursed_and_completed", "get_total_and_overdue_fa", "get_reg_and_active_customer"].includes(selectedTab) &&
            (filter_provider || (!filter_provider && val))
          ) {
            this.setState({ filter_provider: val, data: false, tab_loder_div: false }, () => {
              this.get_chart_report();
            });
          } else if (chart_view && selectedTab === "get_portfolio_chart") {
            this.setState({ acc_prvdr_codes: [], filter_provider: val }, () => this.handleAccPrvdr(val));
          } else if (val) {
            this.get_prvdr_data("name", val);
          } else {
            this.setState({ data: backup_data });
          }
        }
      };
    

    filter_chart_data = async (data, filter) => {  
        if(!this.state.chart_view || this.state.loader||!this.state.pardays||!data)return;
        this.setState({ chart_data_recieved: false });
        let final_data = []
        if (this.state.country_code != '*') {
          data = Object.keys(data).reduce((acc, key) => {
            if (this.state.acc_prvdr_codes.length > 0) {
              if (this.state.acc_prvdr_codes.includes(key)) {
                acc[key] = data[key];
              }
            }
            else if (key == this.state.country_code) {
              acc[key] = data[key];
            }
            return acc;
          }, {});
        }
        const og_data = await Promise.all(
          Object.keys(data).map(async (key, index) => {
            let series_data = {};
            if (key !== "*") {
              series_data['name'] = key;
            } else {
              series_data['name'] = "Global";
            }
            const active_currency = this.state.activeCurrencyVal ? this.state.activeCurrencyVal : 1;
            var modifiedData;
            if (this.state.country_code != '*') {
              modifiedData = Object.keys(data).filter((eachkey) => {
                return data[eachkey] == this.state.country_code
              })
            }
            
            modifiedData = data[key].map((each) => {
              const newData = { ...each };
              if(!this.state.req_perc_data){
                newData['y'] *= active_currency;
              }
              return newData;
            });
        
    
            var mydata = modifiedData;
            let parSeriesData
            final_data = []
            if (this.state.pardayWise || this.state.country_code == '*'||(!this.state.pardayWise&& this.state.country_code == '*')) {
              for (let i = 0; i < this.state.pardays.length; i++) {
                parSeriesData = {
                  name: 'PAR ' + this.state.pardays[i].value,
                  data: modifiedData.filter((each) => each.par_day == this.state.pardays[i].value)
                };
                final_data.push(parSeriesData)
              }
            }
            
            else if (this.state.acc_prvdr_codes.length == 0 || this.state.acc_prvdr_codes[0] === null) {
              parSeriesData = {
                name: this.state.country_code,
                data: data[key].map((each) => {
                  const newData = { ...each };
                  if(!this.state.req_perc_data){
                    newData['y'] *= active_currency;
                  }
        
                  return newData;
                }).filter((each) => each.par_day == this.state.pardays.value)
              };
              final_data.push(parSeriesData)
            }
            else if (this.state.acc_prvdr_codes && this.state.acc_prvdr_codes.length > 0 && this.state.acc_prvdr_codes[0] !== null) {
              for (let i = 0; i < this.state.acc_prvdr_codes.length; i++) {
                if (!data[this.state.acc_prvdr_codes[i]]) continue;
                parSeriesData = {
                  name: this.state.acc_prvdr_codes[i],
                  data: data[this.state.acc_prvdr_codes[i]].map((each) => {
                    const newData = { ...each };
                    if(!this.state.req_perc_data){
                        newData['y'] *= active_currency;
                      }
          
                    return newData;
                  }).filter((each) => each.par_day == this.state.pardays.value)
                }
                final_data.push(parSeriesData)
              };
    
            }
            series_data['series'] = modifiedData;
            return series_data;
            
          })
        );
       
        this.setState({ original_row_datas: final_data.length > 0 ? final_data : og_data, chart_data_recieved: true, },()=>this.reportChartData());
      };
      handleAccPrvdr = (val) => {
        if(!val){
          this.setState({acc_prvdr_codes : [this.state.country_code],tab_loder_div:false,
            },()=>this.filter_chart_data(this.state.chart_data))
          return;
        }else if(this.state.acc_prvdr_codes.length>0&&this.state.acc_prvdr_codes[0]==this.state.country_code){
          this.setState({acc_prvdr_codes : [],tab_loder_div:false,
            })
        }
        if(!this.state.pardayWise){
            if (!this.state.acc_prvdr_codes.includes(val)) {
                this.setState(
                    (prevState) => ({
                        acc_prvdr_codes: [...prevState.acc_prvdr_codes, val],tab_loder_div:false,
                        
                    }),
                    () => {
                        this.filter_chart_data(this.state.chart_data);
                    }
                );
            } else {
                this.setState(
                    (prevState) => ({
                        acc_prvdr_codes: prevState.acc_prvdr_codes.filter((code) => code !== val),tab_loder_div:false,
                        
                    }),
                    () => {
                        this.filter_chart_data(this.state.chart_data);
                    }
                );
            }
          }else{
            this.setState({acc_prvdr_codes : [val],tab_loder_div:false,
                },()=>this.filter_chart_data(this.state.chart_data))
          }
        
        }
        set_pardays(array){
            if(this.state.pardays != array.pardays && !this.state.chart_view){
              this.setState(array,()=>this.setState({tab_loder_div:false,
                }))
            }
            if(this.state.pardays != array.pardays && this.state.chart_view && this.state.chart_data_recieved){
              this.setState(array,()=>this.setState({tab_loder_div:false,
                }))
            }
            
          }

        handleViewBy = (toggle_val) =>{
        if(this.state.viewBy !== toggle_val){
            this.setState({'viewBy' : toggle_val}, ()=>{
                if(this.state.viewBy==true){
                    this.setState({toggle_div:"hide_div",show_filter:false,})
                }else{
                    this.setState({toggle_div:"",show_filter:true,show_target:false})
                    
                }
            })
        }
        if(toggle_val){
            if (!["get_advances_disbursed_and_completed", "get_total_and_overdue_fa","get_reg_and_active_customer"].some(tab => this.state.selectedTab.includes(tab))) 
                {
                    this.setState({filter_provider:'All',current_prvdr_tab:this.state.selectedTab,data:false,tab_loder_div:false,}, () => {
                        this.get_chart_report(this.state.current_prvdr_tab);
                        });
                }
        }
        else{
            this.setState({filter_provider: null,filter_value:null,current_prvdr_tab:this.state.selectedTab,data:false,tab_loder_div:false,}, () => {
                this.get_chart_report(this.state.current_prvdr_tab);
                });
        }
    }

    handleClick = (event,name) => {
        if(event === 'show_target'){

            this.setState({[name] : event.target.checked,mixed_chart
                :false,tab_loder_div:false,
            },()=>this.reportChartData())
        }
        else{
            this.setState({[name] : event.target.checked,mixed_chart:false,tab_loder_div:false,},()=>this.reportChartData())
        }
    }


    handleToggleCummulative = (isCummulative) => {
            this.setState({ cummulative: isCummulative,tab_loder_div:false, });
        }

    activeprojection = (tab, title, isactive, target = false, par_chart = null) => {
        const { viewBy, current_prvdr_tab } = this.state;
        if (viewBy) {
            this.setState({filter_provider: null,filter_value: null,data: false,tab_loder_div: false,viewBy:false
            }, () => {
                this.get_chart_report(current_prvdr_tab);
                this.reportChartData();
            });
        }
    
        this.setState({selectedTab: tab,isactive: isactive,target: target,par_chart: false,selectedChart: title,chart_options: false,data: false,show_target: false,backup_data: false,filter_type: null,filter_provider: null,current_prvdr_tab: null,mixed_chart: false,tab_loder_div: false
        }, () => {
            if (par_chart === 'par_chart') {
                this.setState({
                    par_chart: true,
                    data: false,
                    tab_loder_div: false,
                }, () => this.get_gds_report());
            } else if (par_chart === 'cohort_chart') {
                this.setState({
                    data: false,
                    tab_loder_div: false,
                }, () => this.cohortReportCharts());
            } else {
                this.setState({ par_chart: false }, () => this.reportChartData());
            }
        });
    };
        

handleOptionChange = (event,series) => {
    const month = parseInt(event.target.value);
    this.setState({selectedMonth:month,data:false,tab_loder_div:false,
        },()=>this.reportChartData())
  };

handleToggledaterange = (res,state_val) =>{
        var count=0
        if(state_val=="report_type_is_weekly"){
        count=count+1
        }
          this.setState({[state_val]:res,cohortReportAPIcount:count,data:false,tab_loder_div:false,filter_provider:null},()=>this.reportChartData())

      }

handleAmountToPercentage =()=>{
    this.setState({req_perc_data : !this.state.req_perc_data,},()=>this.get_gds_report())
}

  toCalculateProjection = (array,key) => {
    const projectionCalculations = {};
    const isCummulative = !!key
    Object.keys(array&&array).forEach(key => {
        const selectedMonth = this.state.selectedMonth;
        const data = array[key];
        const projectionData = Array.isArray(data) ? data : [];
        const growthData = projectionData.slice(-selectedMonth - (isCummulative ? 2 : 1), -1)
        if (growthData.length === 0) {
            return projectionCalculations; 
          }
        const difference = isCummulative ? Math.abs(growthData[growthData.length - 1].y - growthData[0].y) :growthData.reduce((acc, cur) => acc + cur.y, 0);
        const totalaverage=difference / selectedMonth
        const avgGrowthRate = Math.round(isCummulative?totalaverage:Math.abs(totalaverage-(growthData[growthData.length - 1].y)));
        const projection = [];
  
      for (let i = 0; i < (projectionData.length - 1); i++) {
        const data = projectionData[i];
        projection.push({ x: data.x, y: data.y });
      }
    const givenDate = new Date(this.state.endDate);
    const currentDate = new Date();
    const monthsDiff = (givenDate.getFullYear() - currentDate.getFullYear()) * 12 + (givenDate.getMonth() - currentDate.getMonth())+1
    this.setState({projectionMonthDiff:monthsDiff})
      for (let i = 1; i <= monthsDiff; i++) {
        const projectedRevenue = projection[projection.length - 1].y + avgGrowthRate;
        projection.push({ x:  moment(growthData[growthData.length - 1].x).add(i, 'months').date(1).format('YYYY-MM-DD'), y: projectedRevenue });
      }
  
      projectionCalculations[key] =projection;
    });
    return projectionCalculations
  }

  cohortReportCharts(filter = null) {
    var req = { country_code: this.state.country_code, report_type: "cohort_report", chart_wise_cohort: true, 
    start_month:  moment(this.state.startDate).format('YYYY-MM-DD')  ,
     end_month:  moment(this.state.endDate).format('YYYY-MM-DD'), 
     date_range: this.state.report_type_is_weekly ? 'weekly' : "monthly"}
    req.lender = this.state.lender;

    if ((moment(this.state.endDate).format('M')) == (moment().format('M'))) {
         if(this.state.report_type_is_weekly){
            var days =  Config("cohort_chart_date_diff").weekly
            var subtract_date = moment().subtract(days, 'days').startOf('week')
            req["end_month"] =  subtract_date.format('YYYY-MM-DD') 
            req["start_month"] =  moment(this.state.startDate).format('YYYY-MM-DD') 
            req["date_range"] = "weekly"
            this.setState({ cohort_date_note: true, req_week: subtract_date.format('YYYY MM DD'),subtract_month_note: true })

        }  else if (!this.state.report_type_is_weekly) {
            var days =Config("cohort_chart_date_diff").monthly 
            var subtract_month = moment().subtract(days, 'days')
            req["end_month"] =  subtract_month.format('YYYY-MM-DD') 
            req["start_month"] =  moment(this.state.startDate).format('YYYY-MM-DD') 

            req["date_range"] = "monthly"

            this.setState({ subtract_month_note: true, req_week: subtract_month.format('YYYY MM DD')})
        }
        }else{

            this.setState({ subtract_month_note: false,data:false },()=>this.reportChartData())

        }

     getReport(this.req(req))
    .then((response) => {
        if(!response) {return;}
        if(response.status === "success"){

            const cohortDataRaw = response.data.data_in_amount
            const cohortData = this.state.report_type_is_weekly ?this.convert_x_axis_data(cohortDataRaw,this.state.activeCurrencyVal? 'currency' :"" ):  this.make_series_data( cohortDataRaw,'currency');
            const cohortDatasCount = this.state.report_type_is_weekly ? this.convert_x_axis_data(response.data.data_in_count) : this.make_series_data(response.data.data_in_count)
            
            const cohortPercentageDatas = this.state.report_type_is_weekly ? this.convert_x_axis_data(response.data.percentage_wise) : this.make_series_data(response.data.percentage_wise)
            this.setState({cohortData,cohortDataRaw,cohortDatasCount,cohortPercentageDatas,data:false
            ,cohortDataDiv:true},()=>this.reportChartData())
        }else{
            alert(response.message + "\n" + JSON.stringify(response.data));
            this.setState({tab_loder_div:true})
        }
    });
  }

  convert_x_axis_data = (array,type) =>{
    var series = [];
    const curVal = this.state.activeCurrencyVal == '' ? 1 : this.state.activeCurrencyVal
    var orginal_data = array?JSON.parse(JSON.stringify(array)):{};
    var data_arr = []
    var startDate = this.state.startDate
    var endDate = this.state.endDate
    var dateWiseFilter=this.state.dateWiseFilter;
    if (startDate !== "" || endDate !== "") {
        if(startDate === ""){
            startDate = moment("2018-01-01").format();
        }else {
            if(dateWiseFilter){
                startDate= moment(startDate).format();
            }
            else{
                startDate= moment(startDate).startOf('month').format();

            }
        }
        if(endDate === ""){
            endDate = moment().format()
        }else{
            if(dateWiseFilter){
                endDate= moment(endDate).format();
            }
            else{
                endDate= moment(endDate).endOf('month').format();
            }
        }
       
            data_arr = orginal_data
            Object.keys(orginal_data).map(function(key) {
                   orginal_data[key].map(function (each, idx) {
                    let current_value = orginal_data[key][idx]['x']

                    const year = current_value&&current_value.toString().substring(0, 4);
                    const weekNumber = current_value&&current_value.toString().substring(4);
                    const date = new Date(year, 0, 1); 
                    const daysToAdd = (weekNumber - 1) * 7; 
                    date.setDate(1 + daysToAdd); 
                    const month = date.toLocaleString('default', { month: 'long' });
                    const weekInMonth = `W${Math.ceil(date.getDate() / 7)}`;
                    const formattedOutput = `${year}-${month.charAt(0).toUpperCase()}${month.slice(1)}-${weekInMonth}`; 
                     
                    orginal_data[key][idx]['x']=formattedOutput

                })
            })
        
    }
    else{
        data_arr = orginal_data
    }
    Object.keys(data_arr).map(function(key){
        var series_data = {};
        if(key!="*"){
         series_data['name'] = key
        }else{
            series_data['name'] = "Global"
        }
        
        if(type == 'currency' && key != '*'){
            data_arr[key].map(function (each,idx){
                if(idx != "type"){
                    data_arr[key][idx]['y'] = data_arr[key][idx]['y'] * curVal
                }
            })
        }
        series_data['data'] = data_arr[key]
        if("type" in data_arr[key]){
            series_data['type'] = data_arr[key]["type"];
        }
        series.push(series_data)
    });
    return series;
}
formatOptionLabel = ({ label }) => {
    return label;
  };    

 handleActive = (tab) =>{
    if(tab == this.state.selectedTab){ 
        return {
            border: '3px solid white', 
            backgroundColor: '#3149A1' 
        }
    }
 }

 selectedGroup = (group) =>{
    const tab = this.state.selectedTab;
    const chartGroups = {
        'Customer Registration': ['get_monthly_cust_report','get_reg_and_active_customer'],
        'Revenue': ['get_revenue', 'get_revenue_per_cust', 'get_revenue_per_fa'],
        'FA Overdue Recovery':['overdue_visits','overdues_recovered','total_overdues'],
        'Portfolio Quality': ['get_ontime_payments', 'get_total_and_overdue_fa', 'get_portfolio_chart', 'cohort_report'],
        'Portfolio size':['get_tot_disb_report','get_outstanding_fa','get_maximum_outstanding_fa','average_Fa_amount','get_advances_disbursed_and_completed','get_tot_settled_disb_count']
    };
    const boldStyle = {
        fontWeight: 'bold'
    };
    if (chartGroups[group].includes(tab)) {
        return boldStyle;
    }
    return {}
 }
 closeOptions = () =>{
    if (this.state.chart_options){
        this.setState({chart_options:false},()=>
        this.handleButtonClick(this.state.selectedTab))
    }
 }
 
 showchartoptions = () =>{
    if (!this.state.chart_options){
        this.setState({chart_options:true,temp_tab:this.state.selectedTab})
    }
 } 
 selectedChart = () =>{
        return <div className=' '>
            <div className='d-flex' >
                <p className='text-white' style={{fontSize:'20px'}}> {this.state.selectedChart}</p>
                {this.state.chart_options ? <MdKeyboardDoubleArrowUp color='#4EA5FF' size={20} alt='icon' onClick={this.closeOptions} style={{marginTop:'6px',marginLeft:"18px"}}/>:
                <MdKeyboardDoubleArrowRight color='#4EA5FF' size={20} alt='icon' onMouseEnter={this.showchartoptions} style={{marginTop:'7px',marginLeft:"0px"}}/>
                }
            </div>
        
        </div>

 }

 handleApiCall = (country_code,screen) => {
    var acc_prvdr_code = this.acc_prvdr_code;
    country_code=this.state.country_code
    screen='borrower_search'
    this.setState({flow_rel_mgr_dd:[],sub_lender_code_dd:[],region_dd:[]})
    getLoanSearchCriteria(this.req({country_code, screen, acc_prvdr_code, "status": "enabled",'is_selected_contry':true}))
                .then((response) => {
                  if(!response){return };
                var result = response.data;
                for (var dd_name in result) {
                    var name_list = result[dd_name];
                    if(dd_name == "sub_lender_code"){
                        name_list.unshift({ data_code: null, data_value: 'ALL' });
                        var lender_codes = this.convertKeys(name_list,'data_code','data_value')
                        this.setState({sub_lender_code_dd:lender_codes})
                    }	
                }
           });
        }

        convertKeys = (arrayOfObjects,id,name)=> {
            return arrayOfObjects.map(obj => ({
              value: obj[id],
              label: obj[name],
              ...obj
            }));
          }
       

  myloader = (state,addl_state=true )=>{return(
    (state && addl_state)?
        <p className='text-white text-center mt-5'>
            No data Found
        </p> 
     :
     <div style={{marginTop:"15%"}} > <p className='text-light mt-3 text-center'>Loading...</p>
         <div className="product_takeup_loader"></div>
     </div>
     )}

     handleButtonClick = (id = 'default_chart') => {
        const defaultChartElement = document.getElementById(id);
        if (defaultChartElement) {
          defaultChartElement.click(); 
        }
      };
      handleLenderChanges=(event)=>{
        this.setState({lender: event.value, current_lender_item : event,showchartoptions:false},()=>this.reportChartData());
    }
    dropdownStyles = ( ) => {
        const dropdownStyles = {
            control: (provided, state) => ({
                ...provided,
                backgroundColor: '#333b50',   
                border: '#333b50',
                borderRadius:'10px',
                }),
            option: (provided) => ({
              ...provided,
              color: 'white', 
              backgroundColor:'#333b50'
            }),
            input: (provided) => ({
                ...provided,
                color: 'white', 
              }), 
            singleValue: (provided) => ({
                ...provided,
                color: 'white',
            }),
          };
          return dropdownStyles;
     }
      LenderCodes = () =>{
        return(
            <div className='dropdown dark_theme_bg  p-0' style={{width:'190px'}}>
                <Select
                    onChange={this.handleLenderChanges} 
                        value = {this.state.current_lender_item}
                        options={this.state.sub_lender_code_dd} 
                        placeholder="Lender Code"
                        styles={this.dropdownStyles()}
                        classNamePrefix="my-select"
                />
            </div>
        )
      }

      handleFilterChange = (Type, provider) => {
        
        const { selectedTab, filter_type, filter_provider, viewBy } = this.state;
    
        if (Type && !viewBy && !filter_provider && filter_type !== Type) {
          this.setState({current_prvdr_tab: selectedTab,filter_type: Type,show_target: false,filter_provider: provider,data: false,tab_loder_div: false,
            },
            () => {
              this.get_chart_report();
            }
          );
        } 
        else if (!Type && filter_type) {
          this.setState(
            {current_prvdr_tab: selectedTab,filter_type: null,show_target: false,filter_provider: null,data: false,tab_loder_div: false,
            },
            () => {
              this.get_chart_report();
            }
          );
        } 
        else if (filter_type && filter_provider !== provider) {
          this.setState(
            {current_prvdr_tab: selectedTab,filter_type: Type,filter_provider: provider,show_target: false,data: false,tab_loder_div: false,
            },
            () => {
              this.get_chart_report();
            }
          );
        }
        
      };


      get_chart_report = (current_prvdr_tab = null) => {

        var req = {
                  "country_code" : this.state.country_code,
                  "show_chart":true,
                  "date":this.state.setDate,
                  "time_zone":get('market').time_zone,
                  "country_code":this.state.country_code, 
                  "acc_prvdr_code":this.state.gds_report_active_accPrvds,
                  "report_type":this.state.selectedTab,
                    "req_perc_data":this.state.req_perc_data,
                    "lender":this.state.lender,
                    'dateWiseFilter':this.state.dateWiseFilter,
                    'currency_code' : this.state.active_currency
                    } 
        if (this.state.filter_type) {
            req.key_name = 'filter_value';
        } else if (this.state.filter_provider && this.state.country_code !== '*') {
            req.key_name = 'acc_prvdr_code';
        } else {
            req.key_name = 'country_code';
        }
        req.lender = this.state.lender;
        req.filter_type = this.state.filter_type;
        req.filter_provider = this.state.filter_provider;
        if(current_prvdr_tab !== null){
            req.report_type = current_prvdr_tab;
        }
        req.chart_weekly = this.state.chart_weekly;
        if (req.report_type === "get_monthly_cust_report") {
        this.handlemMonthlyCustReport(req);
        req.report_type = "get_monthly_cust_report_of_country";
        this.handlemMonthlyCustReportOfCountry(req);
        }
        else if(req.report_type == "get_reg_and_active_customer"){
        this.handleRegAndActiveCustReport(req);
        req.report_type="get_target";
        this.handleTarget(req);
        }
        else if(req.report_type == "get_revenue_per_cust" || req.report_type == "get_revenue_per_cust_of_country"){
        req.report_type == "get_revenue_per_cust"
        this.handleRevenuePerCustReport(req);
        req.report_type = "get_revenue_per_cust_of_country";
        this.handleRevenuePerCustReportForCountry(req);
        }
        else if(req.report_type == "get_revenue_per_fa" || req.report_type == "get_revenue_per_fa_of_country"){
        req.report_type == "get_revenue_per_fa" 
        this.handleRevenuePerFAReport(req);
        req.report_type = "get_revenue_per_fa_of_country";
        this.handleRevenuePerFAReportOfCountry(req);
        }
        else if(req.report_type == "get_tot_disb_report" || req.report_type == "get_tot_disb_report_of_country"){
        req.report_type == "get_tot_disb_report"
        this.handleTotDisbursalReport(req);
        req.report_type = "get_tot_disb_report_of_country";
        this.handleTotDisbursalReportOfCountry(req);
        }
        else if(req.report_type == "get_tot_disb_count" || req.report_type == "get_tot_disb_count_of_country"){
        req.report_type == "get_tot_disb_count"
        this.handleTotalDisbCountReport(req);
        req.report_type = "get_tot_disb_count_of_country";
        this.handleTotalDisbCountReportOfCountry(req);
        }
        else if(req.report_type == "get_tot_settled_disb_count" || req.report_type == "get_tot_settled_disb_count_of_country"){
        req.report_type == "get_tot_settled_disb_count"
        this.handleTotalSettledDisbCountReport(req);
        req.report_type = "get_tot_settled_disb_count_of_country";
        this.handleTotalSettledDisbCountReportForCountry(req);
        }
        else if(req.report_type == "get_ontime_payments" || req.report_type == "get_ontime_payments_for_country"){
        req.report_type == "get_ontime_payments" 
        this.handleOntimePaymentReport(req);
        req.report_type = "get_ontime_payments_for_country";
        this.handleOntimePaymentForCountryReport(req);
        }
        else if(req.report_type == "get_outstanding_fa" || req.report_type == "get_outstanding_fa_for_country"){
        req.report_type == "get_outstanding_fa" |
        this. handleOutstandingFAReport(req)
        req.report_type = "get_outstanding_fa_for_country";
        this. handleOutstandingFAForCountryReport(req)
        }
        else if(req.report_type == "get_revenue" || req.report_type == "get_revenue_for_country"){
        req.report_type == "get_revenue_for_country"
        this.handleRevenueReport(req);
        req.report_type = "get_revenue_for_country";
        this.handleRevenueForCountryReport(req);
        }
        else if(req.report_type == "get_total_and_overdue_fa"){
        this.handleTotalAndOverdueFAReport(req)
        }
        else if(req.report_type == "get_advances_disbursed_and_completed"){
        this.handleAdvancesDisbursedAndCompleted(req);
        }
        else if(req.report_type == "average_Fa_amount" ||req.report_type == "average_Fa_amount_for_country" ){
        req.report_type == "average_Fa_amount" 
        this.handleAverageFaAmount(req);
        req.report_type="average_Fa_amount_for_country";
        this.handleAverageFaAmountForCountry(req); 
        }
        else if(req.report_type == "get_maximum_outstanding_fa" || req.report_type == "get_maximum_outstanding_fa_for_country"){
        req.report_type ="get_maximum_outstanding_fa"
        this.handleMaximumOutstandingFAReport(req)
        req.report_type = "get_maximum_outstanding_fa_for_country";
        this.handleMaximumOutstandingFAForCountryReport(req)
        }
        else if(req.report_type == "overdue_visits"){
          this.OverdueVisits(req)
        }
        else if(req.report_type == "overdues_recovered"){
          this.OverduesRecovered(req)
        }
        else if(req.report_type == "total_overdues"){
            this.TotalOverdues(req)
        }
    }
    render(){
        return (
            <div id="" style={{position:'relative'}}>
                <div className="ml-5 m-0 p-0" >
                    <div className='no-padding '>
                        <h5 className='mt-4'>{this.state.reportDate?'Charts (' + this.state.reportDate + ')':'Charts'}</h5>
                    </div>
                    <div className='row p-0 w-100'  onMouseEnter={this.closeOptions }>
                           
                           <ChartSelectionHeader set_attr = {(res)=>this.set_attributes(res) } fromChart={true}   title = {this.state.reportDate?'Charts (' + this.state.reportDate + ')':'Charts'} showMonthRange={true}  default_start_date={this.state.dateWiseFilter==true ?this.state.dateWiseStartDate:this.state.normalstartDate} default_end_date={this.state.dateWiseFilter ? this.state.dateWiseEndDate : this.state.dataProjections == true && this.state.isactive ? this.state.ProjectendDate : this.state.normalendDate} LenderCodes={this.state.country_code != '*'  && get('role_codes')!=="investor" ? true : false} >
                           </ChartSelectionHeader>
                           
                    </div>
                    <div className='show_chart_view-box'>

                        <div className='d-flex '>
                            <div className={"d-flex  justify-content-start "} style={{marginTop:"6px",marginLeft:"9px"}} >
                                {this.selectedChart()}
                            </div>
                        </div>

                        <div className="row ">
                            <div id="liveTrend" className="carousel w-100" data-interval="false" data-bs-keyboard = {true} style={{}}>
                                { this.state.chart_options ?   
                                <>          
                                    <div className="">
                                        <div className="row mt-4 d-flex flex-wrap">
                                            {/* <div className='col-lg-12 col-md-12 col-xl-12 mb-4p row mt-4 d-flex flex-wrap'> */}
                                                <div className={`col-lg-${!this.state.dateWiseFilter ? '5' : '4'} col-xl-${!this.state.dateWiseFilter ? '3' : '3'}`}>
                                                    <p className="pipeline_legend_text mb-0 py-3" style={{ fontSize: '14px', ...this.selectedGroup('Customer Registration') }}>
                                                        <span className='pr-2'><MdPerson color='#4EA5FF' size={30} /></span>
                                                        Customer Registration
                                                    </p>
                                                    <div className='row carousel-indicators m-0'>
                                                        <div className={`col-lg-${!this.state.dateWiseFilter ? '12' : '12'} mb-2`} style= {{ 'padding-left':  '2px', 'padding-right':  '2px'}}>
                                                            <div data-target="#liveTrend" data-slide-to="0" id='default_chart' onClick={()=>{this.activeprojection('get_monthly_cust_report',!this.state.dateWiseFilter ? 'Monthly Customer Registration' : 'Daily Customer Registration',false, false)}}  className="   col-12 justify-content-center  active text-white d-flex  charts_tab pointer" style={this.handleActive('get_monthly_cust_report')} > {!this.state.dateWiseFilter ? 'Monthly Customer Registration' : 'Daily Customer Registration'}</div>
                                                        </div>
                                                        <div className={`col-lg-${!this.state.dateWiseFilter ? '12' : '12'} mb-2`}  style= {{ 'padding-left':  '2px', 'padding-right':  '2px'}}>
                                                            <div id = {'chart_padding get_reg_and_active_customer'} data-target="#liveTrend" data-slide-to="1" onClick={() => {this.activeprojection('get_reg_and_active_customer','Registered Customers vs Active Customers',true, false)}} className="col-12 justify-content-center  text-white d-flex  charts_tab pointer" style={this.handleActive('get_reg_and_active_customer')} >(Registered vs Active) Customers</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={`col-lg-${!this.state.dateWiseFilter ? '7' : '4'} col-xl-${!this.state.dateWiseFilter ? '6' : '5'}`}>
                                                    <p className="pipeline_legend_text mb-0 py-3" style={{ fontSize: '14px', ...this.selectedGroup('Portfolio size') }}>
                                                        <span className='pr-2'><AiFillPieChart color='#4EA5FF' size={30} /></span>
                                                        Portfolio size
                                                    </p>
                                                    <div className='carousel-indicators row d-flex justify-content-start no-margin '>
                                                        <div className={`${!this.state.dateWiseFilter ? 'col-4' :'col-6'} mb-2`} style= {{ 'padding-left':  '2px', 'padding-right':  '2px'}}>
                                                            <div id = {'chart_padding get_tot_disb_report'}  data-target="#liveTrend" data-slide-to="4" onClick={()=>{this.activeprojection('get_tot_disb_report','Amount Disbursed',false)}}  className={` justify-content-center  text-white d-flex charts_tab pointer`} style={this.handleActive('get_tot_disb_report')} >Amount Disbursed</div>
                                                        </div>
                                                        <div className={`${!this.state.dateWiseFilter ? 'col-4' :'col-6'} mb-2`} style= {{ 'padding-left':  '2px', 'padding-right':  '2px'}}>
                                                            <div id = {'chart_padding get_outstanding_fa'}  data-slide-to="8" onClick={() => {this.activeprojection('get_outstanding_fa','Outstanding FA',false)}} className={` justify-content-center  text-white d-flex charts_tab pointer`} style={this.handleActive('get_outstanding_fa')} >Outstanding FA</div> 
                                                        </div>
                                                        <div className={`${!this.state.dateWiseFilter ? 'col-4' :'col-4'} mb-2`}  style= {{ 'padding-left':  '2px', 'padding-right':  '2px'}}>
                                                            <div id = {'chart_padding average_Fa_amount'} data-target="#liveTrend" data-slide-to="11" onClick={() => {this.activeprojection('average_Fa_amount','Average FA Size',false)}} className="justify-content-center  text-white d-flex charts_tab pointer " style={this.handleActive('average_Fa_amount')} >Average FA Size</div> 
                                                        </div>
                                                        {!this.state.dateWiseFilter ?
                                                            <div className={`${!this.state.dateWiseFilter ? 'col-6' :''} mb-2`} style= {{ 'padding-left':  '2px', 'padding-right':  '2px'}}>
                                                                <div id = {'chart_padding get_maximum_outstanding_fa'} data-target="#liveTrend" data-slide-to="12" onClick={() => {this.activeprojection('get_maximum_outstanding_fa','Maximum Outstanding FA',false)}} className=" justify-content-center align-items-center  text-white d-flex charts_tab pointer " style={this.handleActive('get_maximum_outstanding_fa')} >Maximum Outstanding FA</div>
                                                            </div> : ""
                                                        }
                                                        <div className={`${!this.state.dateWiseFilter ? 'col-6' :'col-8'} mb-2`}  style= {{ 'padding-left':  '2px', 'padding-right':  '2px'}}>
                                                            <div data-target="#liveTrend" id ='advances_disbursed_and_completed chart_padding get_advances_disbursed_and_completed' data-slide-to="5" onClick={() => {this.activeprojection('get_advances_disbursed_and_completed',"Advances Disbursed & Advances Completed",false)}} className=" justify-content-center  text-white d-flex charts_tab pointer" style={this.handleActive('get_advances_disbursed_and_completed')} >
                                                                {/* { this.state.viewBy? "Advances Disbursed": "Advances (Disbursed & Completed)"} */}
                                                                Advances (Disbursed & Completed)
                                                                </div>
                                                        </div>
                                                        {/* {this.state.viewBy &&
                                                            <div className="col-4 mb-2" style= {{ 'padding-left':  '2px', 'padding-right':  '2px'}}>
                                                                <div id = {'chart_padding get_tot_settled_disb_count'}  data-target="#liveTrend" data-slide-to="6" onClick={() => {this.activeprojection('get_tot_settled_disb_count','Advances Completed',false)}} className=" justify-content-center  text-white d-flex charts_tab pointer" style={this.handleActive('get_tot_settled_disb_count')} >Advances Completed</div>
                                                            </div>
                                                        } */}
                                                    </div>
                                                </div>

                                                <div className={`col-lg-${!this.state.dateWiseFilter ? '5' : '3'} col-xl-${!this.state.dateWiseFilter ? '3' : '4'}`}>
                                                    <p className="w-auto pipeline_legend_text  mb-0  py-3" style={{ fontSize: '14px',...this.selectedGroup('Revenue')}}><span className='pr-2'><RiLineChartLine color='#4EA5FF' size={30} /></span>Revenue</p>     
                                                    <div className='carousel-indicators row d-flex justify-content-start no-margin' >
                                                        <div className="col-4 mb-2" style= {{ 'padding-left':  '2px', 'padding-right':  '2px'}}>
                                                            <div id = {'chart_padding'} data-target="#liveTrend" data-slide-to="9" onClick={()=>{this.activeprojection('get_revenue','Revenue',true)}} className=" justify-content-center text-white d-flex  charts_tab pointer" style={this.handleActive('get_revenue')} >Revenue</div>
                                                        </div>
                                                        <div className="col-8 mb-2" style= {{ 'padding-left':  '2px', 'padding-right':  '2px'}}>
                                                            <div id = {'chart_padding'} data-target="#liveTrend" data-slide-to="2" onClick={() => {this.activeprojection('get_revenue_per_cust','Revenue per Customer',false)}} className=" justify-content-center  text-white d-flex  charts_tab pointer" style={this.handleActive('get_revenue_per_cust')} >Revenue per Customer</div>
                                                        </div>
                                                        <div className="col-12 mb-2" style= {{ 'padding-left':  '2px', 'padding-right':  '2px'}}>
                                                            <div id = {'chart_padding'} data-target="#liveTrend" data-slide-to="3" onClick={() => {this.activeprojection('get_revenue_per_fa','Revenue per Float Advance',false)}} className=" justify-content-center   text-white d-flex  charts_tab pointer   " style={this.handleActive('get_revenue_per_fa')} >Revenue per Float Advance</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={`col-lg-${!this.state.dateWiseFilter ? '7' : '4'} col-xl-${!this.state.dateWiseFilter ? '3' : '3'}`}>
                                                    <p className="pipeline_legend_text mb-0 py-3" style={{ fontSize: '14px', ...this.selectedGroup('FA Overdue Recovery') }}>
                                                        <span className='pr-2'><RiLineChartLine color='#4EA5FF' size={30} /></span>
                                                        FA Overdue Recovery
                                                    </p>
                                                    <div className='carousel-indicators row d-flex justify-content-start no-margin '>
                                                        <div className="col-6 mb-2" style= {{ 'padding-left':  '2px', 'padding-right':  '2px'}}>
                                                            <div id = {'chart_padding'} data-target="#liveTrend" data-slide-to="9" onClick={() => { this.activeprojection('overdue_visits','Overdue Visits',false) }} className="d-flex justify-content-center align-items-center text-white charts_tab pointer" style={this.handleActive('overdue_visits')}>
                                                                Overdue Visits
                                                            </div>
                                                        </div>
                                                        <div className="col-6 mb-2" style= {{ 'padding-left':  '2px', 'padding-right':  '2px'}}>
                                                            <div id = {'chart_padding'}  data-target="#liveTrend" data-slide-to="3" onClick={() => { this.activeprojection('total_overdues','Overdues',false) }} className="d-flex justify-content-center align-items-center text-white charts_tab pointer" style={this.handleActive('total_overdues')}>
                                                                Overdues
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-2" style= {{ 'padding-left':  '2px', 'padding-right':  '2px'}}>
                                                            <div id = {'chart_padding'} data-target="#liveTrend" data-slide-to="2" onClick={() => { this.activeprojection('overdues_recovered','Overdues Recovered',false) }} className="d-flex justify-content-center align-items-center text-white charts_tab pointer" style={this.handleActive('overdues_recovered')}>
                                                                Overdues Recovered
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={`col-lg-${!this.state.dateWiseFilter ? '5' : '4'} col-xl-${!this.state.dateWiseFilter ? '6' : '3'}`}>
                                                    <p className="pipeline_legend_text mb-0 py-2" style={{ fontSize: '14px', ...this.selectedGroup('Portfolio Quality') }}>
                                                        <img src='/img/Maskgroup.png' alt='icon' />Portfolio Quality
                                                    </p>
                                                    <div className='carousel-indicators row d-flex justify-content-start no-margin '>
                                                        <div className={`col-${!this.state.dateWiseFilter ? '6' : '12'} mb-2`} style= {{ 'padding-left':  '2px', 'padding-right':  '2px',marginTop:"2px"}}>
                                                            <div id = {'chart_padding'} data-target="#liveTrend"  data-slide-to="7" onClick={()=>{this.activeprojection('get_ontime_payments','Ontime Payment',false)}} className={`justify-content-center  text-white d-flex charts_tab pointer`} style={this.handleActive('get_ontime_payments')} >Ontime Payment</div>
                                                        </div>
                                                        {((get('role_codes')!=="investor") && !this.state.dateWiseFilter) &&
                                                            <div className="col-6 mb-2" style= {{ 'padding-left':  '2px', 'padding-right':  '2px',marginTop:"2px"}}>
                                                                <div id = {'chart_padding'}  ddata-target="#liveTrend" data-slide-to="14" onClick={() => {this.activeprojection('get_portfolio_chart','Historical PAR',false,false,'par_chart')}} className=" justify-content-center  text-white d-flex charts_tab pointer " style={this.handleActive('get_portfolio_chart')} >Historical PAR</div>
                                                            </div>
                                                        }
                                                        {!this.state.dateWiseFilter &&
                                                            <div className="col-6 mb-2" style= {{ 'padding-left':  '2px', 'padding-right':  '2px'}}>
                                                                    <div id = {'chart_padding'} data-target="#liveTrend" data-slide-to="13" onClick={() => {this.activeprojection('cohort_report','Recovery Cohort',false,false,"cohort_chart")}} className=" justify-content-center  text-white d-flex charts_tab pointer " style={this.handleActive('cohort_report')} >Recovery Cohort</div>
                                                            </div>
                                                        }
                                                        <div className={`col-${!this.state.dateWiseFilter  ? '6' : '12'} mb-2`} style= {{ 'padding-left':  '2px', 'padding-right':  '2px'}}>
                                                            <div id = {'chart_padding'}  data-target="#liveTrend" data-slide-to="10" onClick={() => {this.activeprojection('get_total_and_overdue_fa','Total & New Overdue FAs',false)}} className={` justify-content-center  text-white d-flex charts_tab pointer`} style={this.handleActive('get_total_and_overdue_fa')} >Total & New Overdue FAs</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            {/* </div> */}
                                        </div>
                                        <div className = 'show_chart_view-box'  style={{width:'100%',height:'225px',marginTop:'3%'}} onMouseEnter={this.closeOptions } >
                                        </div>
                                    </div>                                    
                                </>
                                :
                                <>    
                                    {(this.state.selectedTab=="overdues_recovered" || this.state.selectedTab == "overdue_visits" || this.state.selectedTab=="cohort_report" || this.state.selectedTab == "total_overdues") && (!this.state.dateWiseFilter ) &&
                                        <div className='d-flex justify-content-between'>
                                            <div className='cohort_switch_padding chart_toggle justify-content-start'>
                                                {(get('role_codes')!=="investor" && this.state.selectedTab !== "total_overdues") ? 
                                                <Toggle className="p-0" id="report_type_is_weekly" width="100" leftLabel= "Monthly" rightLabel="Weekly" fromChart={false} fontSize="15"  val={this.state.report_type_is_weekly?true:false} aligntext="left" set_tog_val={(res) => this.handleToggledaterange(res,"report_type_is_weekly")}  chartfield = {true} /> :""
                                                } 
                                            </div>
                                            {(this.state.selectedTab !=="overdue_visits") ?
                                            <div className='cohort_switch_padding chart_toggle justify-content-end'>
                                                <Toggle className="p-0" id={'cohort_amount_type'} width="100" leftLabel= "Amount" rightLabel="Count" fontSize="15" fromChart={false} val={this.state.cohort_amount_type?true:false} aligntext="left" set_tog_val={(res) => this.handleToggledaterange(res,'cohort_amount_type')} chartfield = {true} /> 
                                            </div>:""
                                            }
                                        </div>
                                    }                
                                    {(this.state.selectedTab=="get_portfolio_chart") && (!this.state.dateWiseFilter) &&
                                        <div className='d-flex'>
                                            <div className="d-flex col justify-content-start align-items-center" style={{marginLeft:"-16px"}}>
                                                <MultiSelecter pardays={pardays} default_pardays = {default_pardays} chart_view = {!this.state.pardayWise} isMulti={this.state.pardayWise} set_attr = {(res)=>this.set_pardays(res)}></MultiSelecter>
                                            </div>                

                                            <div className="d-flex justify-content-end pb-2 chart_toggle pr-0 mr-0" style={{overflow:'hidden'}}>
                                                <Toggle width="100" id='Amount_to_percentage' leftLabel= {"Amount"} rightLabel={"Percentage"} fontSize="15" fromChart={false}  val={this.state.req_perc_data} set_tog_val={(res) => this.handleAmountToPercentage(res)} chartfield = {true}/>
                                            </div>
                                        </div>
                                    }
                                    <div className='d-flex justify-content-between'>
                                        <div >
                                        {this.state.show_target === false  &&
                                            <ChartFilter onFilterChange={this.handleFilterChange} country_code={this.state.country_code} target_state={this.state.show_target} selected_tab={this.state.selectedTab} disable_provider={this.state.filter_type} acc_prvdr_changed={this.acc_prvdr_changed} dateWiseFilter={this.state.dateWiseFilter} lender={this.state.lender} viewBy={this.state.viewBy} acc_prvdr={this.state.filter_provider} filters={this.state.filter_type} report_weekly={this.state.report_type_is_weekly} amount_type={this.state.cohort_amount_type}  start_date={this.state.startDate} end_date ={this.state.endDate} />
                                        }
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            {
                                            check_priv("charts", "target_projection")&&
                                            this.state.isactive && !this.state.dateWiseFilter && (this.state.selectedTab=='get_reg_and_active_customer') &&
                                            <div className={"d-flex ml-3 justify-content-center"}>
                                                <label>
                                                    <input type={"checkbox"} checked={this.state.dataProjections} onClick={(event) => this.handleClick(event, "dataProjections")} />
                                                    <span style={{ "fontSize": "14px" }}> Show Projections based on last &nbsp;
                                                    <select className='text-white bg-dark' value={this.state.selectedMonth} onChange={this.handleOptionChange} id="select-option" disabled={!this.state.dataProjections} >
                                                        {Array.from(Array(12)).map((_, index) => (
                                                            <option key={index} value={index+1 } >{index+1}</option>
                                                        ))}
                                                        </select> months
                                                    </span>
                                                </label>
                                            </div>
                                            } 
                                        </div>
                                        <div>

                                            {( this.state.selectedTab === "overdue_visits"  ) ? "":
                                                <>
                                                {this.state.country_code !="*" && this.state.filter_type === null && this.state.show_target === false && 
                                                    <div className="justify-content-end  w-100 d-flex p-0 par_chart_toggle chart_toggle" >
                                                        {(this.state.selectedTab !="cohort_report") && !this.state.lender &&
                                                            <>
                                                                {this.state.par_chart && this.state.selectedTab == "get_portfolio_chart" ?
                                                                    <Toggle width="100" id='provider' leftLabel= {"Provider"} rightLabel={"Par Days"} fontSize="15"  fromChart={false} val={this.state.pardayWise} set_tog_val={(res) => this.handleproviderWise(res)} fontFamily ={'Montserrat'} chartfield = {true}/>
                                                                    :
                                                                    <Toggle width="140" leftLabel={"All " + this.state.country} rightLabel="All Provider" fromChart={false}   chart_toggle val = {this.state.viewBy} set_tog_val = {(res) => this.handleViewBy(res)} fontFamily ={'Montserrat'} chartfield = {true}/>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                }
                                                </>
                                            }         
                                            {/* <div className='row pl-5'>
                                                <div className={"d-flex col justify-content-end pb-2 mr-5 marginTopL"} style={{}}>
                                                    <label>
                                                        <input type={"checkbox"} onClick={ (event) => this.handleClick(event,"dataLabel")} value={this.state.dataLabel} defaultChecked={this.state.dataLabel?true:false}/> 
                                                        <span style={{"fontSize" : "14px",marginLeft:'5px'}}>Show Data Label </span>
                                                    </label>
                                                </div>
                                            </div> */}

                                            {this.state.filter_type === null &&
                                                check_priv("charts", "target_projection") && !this.state.dateWiseFilter && !this.state.viewBy && ((this.state.target !== "cohort_chart") && (this.state.selectedTab ==='get_monthly_cust_report')) ?
                                                <div className="d-flex justify-content-end pb-2 mr-5" >
                                                    <label style={{ marginRight: "30px" }}>
                                                        <input type={"checkbox"} onClick={(event) => this.handleClick(event, "show_target")} defaultChecked={this.state.show_target} />
                                                        <span style={{ "fontSize": "14px" ,"margin-left": "6px" }}>Show Target </span>
                                                    </label>
                                                </div>
                                                : null
                                            }                                            
                                            {this.state.cohortDataDiv && (this.state.selectedTab=="cohort_report") && (!this.state.dateWiseFilter) && (!this.state.cohort_amount_type) &&
                                                <div className={"d-flex justify-content-end pb-2 mr-5"}>
                                                    <label>
                                                        <input type={"checkbox"} onClick={ (event) => this.setState({cohort_data_in_perc:event.target.checked},()=>this.reportChartData())} checked={this.state.cohort_data_in_perc } defaultChecked={this.state.cohort_data_in_perc}/> 
                                                        <span style={{"fontSize" : "14px"}}>Percentage Wise </span>
                                                    </label>
                                                </div>
                                            }   
                                        </div> 
                                    </div>
                                
                                    <div className="carousel-inner" style={{ overflow: 'hidden' }}>

                                        {this.state.data !== false ?
                                            <div className="content-section implementation">
                                                <>
                                                    <ReportChart data={this.state.data} type={this.state.chart_type}   xaxistype={this.state.xaxistype} x_axis_format={this.state.x_axis_format} colors={this.state.colors}  dataProjections ={this.state.dataProjections} projectionMonthDiff={this.state.projectionMonthDiff}  curr = {this.state.curr}  roundOff={this.state.roundOff} target={this.state.target ? this.state.target_data : undefined} mixed_chart={this.state.mixed_chart}
                                                    />
                                                    {this.state.subtract_month_note && this.state.selectedTab === 'cohort_report' ?
                                                        <p className=' text-center' style={{ marginTop: '-35px' }}> <span style={{ color: 'red' }}>Note *</span> <span className='text-white'>This chart is displayed based on the data upto { this.state.req_week ? moment(this.state.req_week).format("YYYY MMMM  DD"):"" }</span> </p> : ""
                                                    }
                                                </>
                                            </div> : 
                                            this.myloader(this.state.tab_loder_div)
                                        }
                                        
                                    </div>
                                </>
                                }
                            </div>
                        </div> 
                        
                    </div>          
            </div>
        </div>
        
        );
    }
}
export default ReportChartLive;